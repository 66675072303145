import {
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
  DateRangePicker,
} from 'react-dates';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import 'react-dates/lib/css/_datepicker.css';

const MultiDatePicker = ({
  minDate = false,
  initialStart,
  initialEnd,
  onChange,
}) => {
  const [focused, setFocused] = useState();

  return (
    <div className="multi-date-picker mt-2">
      <DateRangePicker
        startDate={initialStart}
        startDateId="startDate"
        endDate={initialEnd}
        endDateId="endDate"
        onDatesChange={onChange}
        focusedInput={focused}
        onFocusChange={(focused) => setFocused(focused)}
        isOutsideRange={(day) => {
          if (minDate) {
            return (
              !isInclusivelyBeforeDay(day, moment()) ||
              !isInclusivelyAfterDay(day, minDate)
            );
          }

          return !isInclusivelyBeforeDay(day, moment());
        }}
        minimumNights={0}
        displayFormat="MMM D, YYYY"
        noBorder
        readOnly
        block
      />
    </div>
  );
};

export default MultiDatePicker;

import { canContact as canContactAction } from 'actions/auth';
import { postRequest } from 'utils/fetch';

import { createThunk } from '../create-thunk';

export const canContact = createThunk({
  handler: async ({ args }) => {
    await postRequest('/user/contact', { canContact: args.canContact }, true);

    return { canContact: args.canContact };
  },
  onSuccess: [canContactAction],
  onFailure: [canContactAction],
});

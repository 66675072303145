/***
 * Creates a reducer map
 * @param {Object} actionMap - A map of actions that will be used to handle action types {[type]: handler}
 * @param {*} defaults
 * @returns {function(*=, *)}
 */
export const reducerFactory = (actionMap, defaults) => (
  state = defaults,
  action
) => {
  const doAction = actionMap[action.type];
  return doAction ? doAction({ state, action }) : state;
};

import { useSelector } from 'react-redux';
import moment from 'moment';
import React, { useState } from 'react';

import { getRequest } from 'utils/fetch';
import { usePageTitle } from 'hooks/use-page-title';
import { useQueryParams } from 'hooks/use-query-params';
import Container from 'components/container';
import Loading from 'components/loading';
import PlanManage from 'components/plan-manage';
import PlanSelect from 'components/plan-select';
import Subheader from 'components/subheader';
import { determineParticipantQuota } from 'utils/determine-participant-quota';

const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const BillingRoute = () => {
  const authData = useSelector((state) => state.auth);
  const [isLoading, setLoading] = useState(false);
  usePageTitle('Billing');
  const query = useQueryParams();

  const handlePortal = async () => {
    setLoading(true);
    try {
      const result = await getRequest(`/billing/portal`);
      if (result.data && result.data.sessionUrl) {
        window.location.replace(result.data.sessionUrl);
      }
    } catch (e) {
      // errorToast('An error has occurred.');
    }
    setLoading(false);
  };

  const handlePlanSelect = async ({ type, period }) => {
    setLoading(true);
    try {
      const result = await getRequest(
        `/billing/checkout?type=${type.toLowerCase()}&period=${period}`
      );
      if (result.data.sessionId) {
        stripe
          .redirectToCheckout({ sessionId: result.data.sessionId })
          .then(function (result) {
            if (result.error) {
              // TODO: error handling
              // If `redirectToCheckout` fails due to a browser or network
              // error, display the localized error message to your customer.
              console.log(result.error);
            }
          });
      }
    } catch (e) {
      // errorToast('An error has occurred.');
    }
    setLoading(false);
  };

  if (authData.isLoading || isLoading) {
    return (
      <div className="text-center min-h-screen bg-gray-50 flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8">
        <Loading className="inline text-indigo-500" width={75} height={75} />
      </div>
    );
  }

  const showSuccessBanner = query.get('type') === 'success';
  const showAppsumoBanner = query.get('type') === 'appsumo';

  return (
    <div>
      <Subheader title="Manage Billing" />
      <Container>
        <div className="mx-auto max-w-4xl">
          <div>
            {showAppsumoBanner && (
              <div className="mb-4">
                <div className="rounded-md bg-green-50 p-4 text-sm font-medium text-green-800">
                  Successfully applied AppSumo code to your account.
                </div>
              </div>
            )}
            {showSuccessBanner && (
              <div className="mb-4">
                <div className="rounded-md bg-green-50 p-4 text-sm font-medium text-green-800">
                  Subscription successfully created.
                </div>
              </div>
            )}
            {authData.subscription &&
              authData.subscription.planCancellationDate && (
                <div className="mb-4">
                  <div className="rounded-md bg-red-50 p-4 text-sm font-medium text-red-800">
                    Your subscription has been cancelled. You will continue to
                    have full access until the end of your current billing
                    period on{' '}
                    <b>
                      {moment(
                        authData.subscription.planCancellationDate
                      ).format('MMMM D, YYYY')}
                    </b>
                    .
                  </div>
                </div>
              )}
          </div>
          <div>
            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">
                  Participant Quota
                </dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {determineParticipantQuota(
                    authData.subscription,
                    authData.promo
                  )}
                </dd>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">
                  Current Usage
                </dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {authData.totalParticipants}
                </dd>
              </div>
              <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">
                  Remaining Participant(s)
                </dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {authData.remainingParticipants === 99999 ||
                  (authData.user.isActive &&
                    !authData.promo &&
                    !!!authData.subscription)
                    ? 'Unlimited'
                    : authData.remainingParticipants}
                </dd>
              </div>
            </dl>
          </div>
          <div className="mt-5 bg-white overflow-hidden mb-6 p-6 shadow rounded-lg">
            {authData && (authData.subscription || authData.promo) ? (
              <PlanManage
                onPortalClick={handlePortal}
                subscription={authData.subscription}
                promo={authData.promo}
              />
            ) : (
              <PlanSelect
                onPlanClick={handlePlanSelect}
                totalParticipants={authData.totalParticipants}
                remainingParticipants={authData.remainingParticipants}
              />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BillingRoute;

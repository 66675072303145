export const reminderTimes = [
  {
    label: 'No reminder',
    value: 0,
  },
  {
    label: 'Remind 15 minutes before',
    value: 15,
  },
  {
    label: 'Remind 30 minutes before',
    value: 30,
  },
  {
    label: 'Remind 45 minutes before',
    value: 45,
  },
  {
    label: 'Remind 1 hour before',
    value: 60,
  },
];

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import sanitize from 'sanitize-filename';

import { determineStats } from 'utils/determine-stats';
import { enableAllQuestions } from 'utils/enable-all-questions';
import { errorToast } from 'utils/toast';
import { getReportsForStandupParticipant } from 'thunks/reports';
import { formatExportParticipantReports } from 'utils/format-export-participant-reports';
import { postRequest } from 'utils/fetch';
import { toastMessages } from 'constants/toast-messages';
import Button from 'components/button';
import LoadingContent from 'components/loading-content';
import ParticipantFilters from 'components/participant-filters';
import ParticipantStats from 'components/particpant-stats';
import Report from 'components/report';

const getInitialDates = (createdDate) => {
  const endDate = moment();
  let startDate;
  if (moment().subtract(7, 'days').isBefore(moment(createdDate))) {
    startDate = moment(createdDate);
  } else {
    startDate = moment().subtract(7, 'days');
  }

  return { startDate, endDate };
};

const ParticipantReports = ({
  standup,
  questions,
  slackUser,
  participantId,
}) => {
  const dispatch = useDispatch();
  const isReportsLoading = useSelector((state) => state.reports.isLoading);
  const reportsByParticipantId = useSelector(
    (state) => state.reports.byParticipantId
  );
  const [questionFilter, setQuestionFilter] = useState(
    enableAllQuestions(questions)
  );
  const [reportDates, setReportDates] = useState(
    getInitialDates(standup.createdAt)
  );

  useEffect(() => {
    dispatch(
      getReportsForStandupParticipant({
        standupId: standup.standupId,
        participantId: participantId,
        minDate: reportDates.startDate.startOf('day').toDate(),
        maxDate: reportDates.endDate.endOf('day').toDate(),
      })
    );
    // eslint-disable-next-line
  }, [reportDates]);

  const handleDownloadReportData = async (reports) => {
    if (!reports) return;

    const reportData = formatExportParticipantReports(
      reports,
      standup.questions,
      questions
    );
    const filename = `${sanitize(slackUser.name).replace(
      ' ',
      '-'
    )}-${reportDates.startDate.format(
      'MMM-D-YYYY'
    )}-${reportDates.endDate.format('MMM-D-YYYY')}.csv`.toLowerCase();

    const data = {
      data: reportData,
      filename,
    };

    try {
      const result = await postRequest(
        `/export/${standup.standupId}/reports`,
        data
      );
      fileDownload(result.data, filename);
    } catch (e) {
      errorToast(toastMessages.DOWNLOAD_ERROR);
    }
  };

  const reportData = reportsByParticipantId[participantId] || [];
  const stats = determineStats(reportData, 1, true);

  return (
    <>
      <div className="mb-5">
        <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <Link
            to={`/standups/${standup.standupId}`}
            className="text-sm leading-6 font-medium text-indigo-500 hover:text-indigo-900"
          >
            &larr; Back to Report Results
          </Link>
          <div className="mt-3 sm:mt-0 sm:ml-4">
            <Button
              type="secondary"
              onClick={() => handleDownloadReportData(reportData)}
            >
              Download Participant Data
            </Button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
        <div className="grid grid-cols-1 gap-4">
          <ParticipantFilters
            reportDates={reportDates}
            questionFilter={questionFilter}
            setReportDates={setReportDates}
            setQuestionFilter={setQuestionFilter}
            standup={standup}
            questions={questions}
            user={slackUser}
          />
        </div>
        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
          <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-2 lg:gap-4">
            {isReportsLoading ? null : <ParticipantStats stats={stats} />}
          </div>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            {isReportsLoading ? (
              <div className="flex flex-col items-center justify-center py-20 text-indigo-500">
                <div className="pb-2 text-gray-800">Loading Reports...</div>
                <div style={{ maxWidth: 80 }}>
                  <LoadingContent />
                </div>
              </div>
            ) : (
              <div className="px-4 pt-5 sm:px-6 sm:pt-6">
                <h2 className="pb-4 text-lg text-gray-800">
                  Participant Report History:{' '}
                  {reportDates.startDate.format('MMMM D, YYYY')} -{' '}
                  {reportDates.endDate.format('MMMM D, YYYY')}
                </h2>
                {reportData.length ? (
                  reportData.map((report) => (
                    <Report
                      key={report.reportId}
                      questionFilter={questionFilter}
                      questions={questions}
                      report={report}
                      showIncompleteDate
                    />
                  ))
                ) : (
                  <p className="pb-6 italic text-gray-700">
                    No results for selected time range.
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ParticipantReports;

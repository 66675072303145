const getHour = (hour) => {
  if (hour === 0) {
    return 12;
  } else if (hour > 12) {
    return hour - 12;
  } else {
    return hour;
  }
};

export const get12HourTime = (hour, minute) => {
  const period = hour >= 12 ? 'PM' : 'AM';
  return `${getHour(hour)}:${minute < 10 ? `0${minute}` : minute} ${period}`;
};

import { Redirect, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { getUser } from 'thunks/auth';
import { localStorageKeys } from 'constants/local-storage';
import { removeLocalStorage } from 'utils/local-storage';
import { usePageTitle } from 'hooks/use-page-title';
import Loading from 'components/loading';

const OAuthSuccessRoute = () => {
  usePageTitle('Logging in...', false);
  const { token } = useParams();
  const dispatch = useDispatch();
  const [isAuthed, setAuthed] = useState(false);

  useEffect(() => {
    if (token) {
      dispatch(
        getUser({ token }, (err) => {
          if (err) {
            window.location.replace(process.env.REACT_APP_MARKETING_URL);
            removeLocalStorage(localStorageKeys.AUTH_TOKEN);
          } else {
            setAuthed(true);
          }
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  if (isAuthed) {
    return <Redirect to="/" />;
  }

  return (
    <div className="text-center min-h-screen bg-gray-50 flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8">
      <Loading className="inline text-indigo-500" width={75} height={75} />
    </div>
  );
};

export default OAuthSuccessRoute;

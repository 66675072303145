import {
  PlusIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/outline';
import React, { useState } from 'react';

import Label from 'components/label';
import Question from 'components/question';
import QuestionEdit from 'components/question-edit';
import ArchivedQuestion from 'components/archived-question';

const StandupQuestions = ({
  questions,
  allQuestions,
  onChange,
  onFullChange,
  standupId,
  isEditing,
}) => {
  const [isCreating, setCreating] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [archiveOpen, setArchiveOpen] = useState(false);

  const onMove = (direction, index) => {
    const newQuestions = [...questions];
    if (direction === 'up') {
      [newQuestions[index - 1], newQuestions[index]] = [
        newQuestions[index],
        newQuestions[index - 1],
      ];
    } else {
      [newQuestions[index], newQuestions[index + 1]] = [
        newQuestions[index + 1],
        newQuestions[index],
      ];
    }

    onChange(newQuestions);
  };

  const onEdit = (questionId) => {
    setEditOpen(questionId);
  };

  const onDelete = (question) => {
    const change = {
      type: 'delete',
      question,
    };
    onFullChange(change);
  };

  const onRestore = (question) => {
    const change = {
      type: 'restore',
      question,
    };
    onFullChange(change);
  };

  const onSave = (newQuestion, isNew = false) => {
    const change = {
      type: isNew ? 'create' : 'update',
      question: newQuestion,
    };
    onFullChange(change);
  };

  const archivedQuestions = Object.values(allQuestions).filter(
    (question) => !questions.includes(question.questionId)
  );

  return (
    <div>
      <div>
        <Label text={`Report Questions (${questions.length}/20)`} />
        <div className="pt-4">
          <QuestionEdit
            isOpen={!!editOpen}
            question={!!editOpen ? allQuestions[editOpen] : false}
            isCreating={isCreating}
            onSave={onSave}
            standupId={standupId}
            onClose={() => {
              setEditOpen(false);
              setCreating(false);
            }}
          />
          {questions.map((q, i) => (
            <Question
              key={q}
              question={allQuestions[q] || ''}
              onMove={onMove}
              onEdit={onEdit}
              onDelete={onDelete}
              onDeleteOpen={() => setDeleteOpen(q)}
              onDeleteClose={() => setDeleteOpen(false)}
              isDeleteOpen={deleteOpen === q}
              onFullChange={onFullChange}
              isFirst={i === 0}
              isLast={i === questions.length - 1}
              canDelete={questions.length > 1 || !isEditing}
              index={i}
            />
          ))}
          {questions.length !== 20 && (
            <button
              type="button"
              className="inline-flex items-center py-2 text-sm leading-4 font-medium text-indigo-600 hover:text-indigo-800 focus:outline-none"
              onClick={() => {
                setCreating(true);
                setEditOpen(true);
              }}
            >
              <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              Add New Question
            </button>
          )}
        </div>
      </div>
      {!!archivedQuestions.length && (
        <div className="mt-4">
          <button
            className="flex text-sm items-center font-medium text-gray-700"
            onClick={() => setArchiveOpen(!archiveOpen)}
          >
            {archiveOpen ? 'Hide' : 'Show'} Archived Questions
            {archiveOpen ? (
              <ChevronUpIcon className="h-5 w-5 ml-2" />
            ) : (
              <ChevronDownIcon className="h-5 w-5 ml-2" />
            )}
          </button>
          {archiveOpen && (
            <div className="mt-4">
              {archivedQuestions.map((q) => (
                <ArchivedQuestion
                  key={q.questionId}
                  question={q || false}
                  onRestore={onRestore}
                  canRestore={questions.length < 20}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StandupQuestions;

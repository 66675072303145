import moment from 'moment-timezone';
import React from 'react';

import { getStandupUsers } from 'utils/get-standup-users';
import Checkbox from 'components/checkbox';
import DatePicker from 'components/date-picker';
import ReportView from 'components/report-view';

const ReportFilters = ({
  participantFilter,
  reportDate,
  questions,
  questionFilter,
  setQuestionFilter,
  setReportDate,
  setParticipantFilter,
  standup,
  users,
  view,
  setView,
}) => {
  const handleQuestionFilterChange = (e, question) => {
    const checked = e.target.checked;
    setQuestionFilter({
      ...questionFilter,
      [question]: checked,
    });
  };

  const handleUserFilterChange = (e, userId) => {
    setParticipantFilter({
      ...participantFilter,
      [userId]: !participantFilter[userId],
    });
  };

  const handleQuestionFilterSelectAll = () => {
    const newQuestionFilter = {};
    Object.values(questions).forEach((question) => {
      newQuestionFilter[question.questionId] = true;
    });
    setQuestionFilter(newQuestionFilter);
  };

  const handleParticipantFilterSelectAll = () => {
    const newFilter = {};
    standup.participants.forEach((participant) => {
      newFilter[participant] = true;
    });
    setParticipantFilter(newFilter);
  };

  const standupUsers = getStandupUsers(users, standup.participants);

  const archivedQuestions = Object.values(questions).filter(
    (question) => !standup.questions.includes(question.questionId)
  );

  return (
    <div>
      <div className="bg-white shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h2 className="text-sm font-medium text-gray-500">Report Date</h2>
          <DatePicker
            minDate={moment(standup.createdAt)}
            value={reportDate}
            onChange={setReportDate}
          />
        </div>
      </div>
      <div className="mt-6 bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h2 className="flex-1 text-sm font-medium text-gray-500">
            View reports by
          </h2>
          <ReportView view={view} setView={setView} />
        </div>
      </div>
      <div className="mt-6 bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex border-b border-gray-200 pb-2 mb-2">
            <h2 className="flex-1 text-sm font-medium text-gray-500">
              Participants
            </h2>
            <button
              className="text-indigo-500 text-sm hover:text-indigo-800"
              onClick={handleParticipantFilterSelectAll}
            >
              Select All
            </button>
          </div>
          <div className="max-h-40 overflow-y-auto">
            {standupUsers.map((user) => (
              <Checkbox
                key={user.id}
                className="p-1"
                label={user.name}
                value={participantFilter[user.id]}
                onChange={(e) => handleUserFilterChange(e, user.id)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="mt-6 bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex border-b border-gray-200 pb-2 mb-2">
            <h2 className="flex-1 text-sm font-medium text-gray-500">
              Questions
            </h2>
            <button
              className="text-indigo-500 text-sm hover:text-indigo-800"
              onClick={handleQuestionFilterSelectAll}
            >
              Select All
            </button>
          </div>
          {standup.questions.map((q) => {
            const question = questions[q];

            if (!question) return null;

            return (
              <Checkbox
                key={question.questionId}
                className="p-1"
                label={question.content}
                value={questionFilter[question.questionId]}
                onChange={(e) =>
                  handleQuestionFilterChange(e, question.questionId)
                }
              />
            );
          })}
          {archivedQuestions.map((question) => (
            <Checkbox
              key={question.questionId}
              className="p-1"
              label={`[Archived] ${question.content}`}
              value={questionFilter[question.questionId]}
              onChange={(e) =>
                handleQuestionFilterChange(e, question.questionId)
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReportFilters;

import { Link, Redirect, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import React from 'react';
import debounce from 'lodash/debounce';

import { CogIcon } from 'components/icon';
import { errorToast } from 'utils/toast';
import { toastMessages } from 'constants/toast-messages';
import { updateStandupEnabled } from 'thunks/standups';
import { usePageTitle } from 'hooks/use-page-title';
import { useQueryParams } from 'hooks/use-query-params';
import Container from 'components/container';
import Loading from 'components/loading';
import StandupCreated from 'components/standup-created';
import StandupReports from 'components/standup-reports';
import StandupSummary from 'components/standup-summary';
import Subheader from 'components/subheader';
import Toggle from 'components/toggle';
import ParticipantSchedule from 'components/participant-schedule';
import { buildUserMap } from 'utils/build-user-map';

const StandupRoute = () => {
  usePageTitle('View Standup');
  const query = useQueryParams();
  const { standupId } = useParams();
  const dispatch = useDispatch();
  const slackData = useSelector((state) => state.slack);
  const isStandupLoading = useSelector((state) => state.standups.isLoading);
  const byStandupId = useSelector((state) => state.standups.byStandupId);
  const questionsByStandupId = useSelector(
    (state) => state.standups.questionsByStandupId
  );
  const isActive = useSelector((state) => state.auth.user.isActive);

  const standup = byStandupId[standupId] || false;
  const questions = questionsByStandupId[standupId] || false;

  const handleEnabledChange = async () => {
    if (!isActive) {
      return;
    }

    dispatch(
      updateStandupEnabled(
        {
          standupId: standup.standupId,
          data: {
            enabled: !standup.enabled,
          },
        },
        (err) => {
          if (err) {
            const error = get(err, 'data.error', false);
            errorToast(
              error
                ? toastMessages.STANDUP_NO_PARTICIPANTS
                : toastMessages.STANDUP_STATUS_ERROR
            );
          }
        }
      )
    );
  };

  const debouncedChange = debounce(handleEnabledChange, 200);

  if (slackData.isLoading || isStandupLoading) {
    return (
      <div className="text-center min-h-screen bg-gray-50 flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8">
        <Loading className="inline text-indigo-500" width={75} height={75} />
      </div>
    );
  }

  if (!standup || !questions) return <Redirect to="/" />;

  const userMap = buildUserMap(slackData.users);

  return (
    <div>
      <Subheader
        title={standup.name}
        sideActions={() => (
          <span>
            <Link
              className="rounded-full p-1 bg-gray-100 inline-block text-gray-700 align-middle mr-4 hover:bg-indigo-100"
              to={`/standups/${standup.standupId}/edit`}
              data-tip="Standup Settings"
            >
              <CogIcon className="h-6 w-6" />
            </Link>
            <span
              className="inline-block align-middle"
              data-tip={`Standup ${standup.enabled ? 'Enabled' : 'Disabled'}`}
            >
              <Toggle value={standup.enabled} onChange={debouncedChange} />
            </span>
          </span>
        )}
      />
      <StandupCreated open={query.get('success') === 'true'} query={query} />
      <Container>
        <div className="bg-white overflow-hidden mb-6 shadow rounded-lg text-center">
          <StandupSummary standup={standup} slackData={slackData} />
          {standup.enabled && (
            <ParticipantSchedule standupId={standupId} userMap={userMap} />
          )}
        </div>

        <StandupReports
          users={slackData.users}
          questions={questions}
          standup={standup}
        />
      </Container>
    </div>
  );
};

export default StandupRoute;

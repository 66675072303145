import { types } from './types';

export const updateStandup = ({ standup }) => {
  return {
    type: types.UPDATE_STANDUP,
    payload: {
      standup,
    },
  };
};

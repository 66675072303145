import { localStorageKeys } from 'constants/local-storage';
import { resetAuth } from 'actions/auth';
import { removeLocalStorage } from 'utils/local-storage';

import { createThunk } from '../create-thunk';

export const logout = createThunk({
  handler: async () => {
    removeLocalStorage(localStorageKeys.AUTH_TOKEN);
    window.location.replace(process.env.REACT_APP_MARKETING_URL);

    return {};
  },
  onSuccess: [resetAuth],
  onFailure: [resetAuth],
});

import { types } from './types';

export const removeStandup = ({ standupId }) => {
  return {
    type: types.REMOVE_STANDUP,
    payload: {
      standupId,
    },
  };
};

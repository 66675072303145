import { localStorageKeys } from 'constants/local-storage';
import { setLoading, setUser } from 'actions/auth';
import { getData } from 'thunks/slack';
import { getStandups } from 'thunks/standups';
import { setLocalStorage } from 'utils/local-storage';
import { getRequest } from 'utils/fetch';

import { createThunk } from '../create-thunk';

export const getUser = createThunk({
  handler: async ({ args }) => {
    const result = await getRequest('/user', args.token);

    const user = result.data.user;
    const subscription = result.data.subscription;
    const token = result.data.token;
    const remainingParticipants = result.data.remainingParticipants;
    const totalParticipants = result.data.totalParticipants;
    const promo = result.data.user.promoType
      ? {
          promoType: result.data.user.promoType,
          promoEndsAt: result.data.user.promoEndsAt,
        }
      : false;

    setLocalStorage(localStorageKeys.AUTH_TOKEN, token);

    return {
      user,
      subscription,
      promo,
      token,
      remainingParticipants,
      totalParticipants,
    };
  },
  onStart: [() => setLoading(true)],
  onSuccess: [() => setLoading(false), setUser, getData, getStandups],
  onFailure: [() => setLoading(false)],
});

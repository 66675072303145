export const updateStandupQuestion = ({ state, action }) => {
  return {
    ...state,
    questionsByStandupId: {
      ...state.questionsByStandupId,
      [action.payload.standupId]: {
        ...state.questionsByStandupId[action.payload.standupId],
        [action.payload.questionId]: action.payload.question,
      },
    },
  };
};

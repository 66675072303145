import moment from 'moment-timezone';
import React from 'react';

import Checkbox from 'components/checkbox';
import MultiDatePicker from 'components/multi-date-picker';

const ParticipantFilters = ({
  reportDates,
  questions,
  questionFilter,
  setQuestionFilter,
  setReportDates,
  standup,
  user,
}) => {
  const handleQuestionFilterChange = (e, question) => {
    const checked = e.target.checked;
    setQuestionFilter({
      ...questionFilter,
      [question]: checked,
    });
  };

  const handleQuestionFilterSelectAll = () => {
    const newQuestionFilter = {};
    Object.values(questions).forEach((question) => {
      newQuestionFilter[question.questionId] = true;
    });
    setQuestionFilter(newQuestionFilter);
  };

  const archivedQuestions = Object.values(questions).filter(
    (question) => !standup.questions.includes(question.questionId)
  );

  return (
    <div>
      <div className="bg-white shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h2 className="pb-4 text-sm font-medium text-gray-500">
            Showing Report History For
          </h2>
          <div className="flex items-center">
            <img
              className="inline-block h-10 w-10 rounded-full ring-2 ring-transparent hover:ring-indigo-500"
              src={user.avatar}
              alt={user.name}
            />
            <div className="ml-4">
              <h3 className="text-lg font-bold text-gray-800 leading-tight">
                {user.name}
              </h3>
              {user.displayName && user.name !== user.displayName && (
                <h4 className="text-sm text-gray-600 leading-none">
                  {user.displayName}
                </h4>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6 bg-white shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h2 className="text-sm font-medium text-gray-500">
            Report History Period
          </h2>
          <MultiDatePicker
            minDate={moment(standup.createdAt)}
            initialStart={reportDates.startDate}
            initialEnd={reportDates.endDate}
            onChange={setReportDates}
          />
        </div>
      </div>
      <div className="mt-6 bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex border-b border-gray-200 pb-2 mb-2">
            <h2 className="flex-1 text-sm font-medium text-gray-500">
              Questions
            </h2>
            <button
              className="text-indigo-500 text-sm hover:text-indigo-800"
              onClick={handleQuestionFilterSelectAll}
            >
              Select All
            </button>
          </div>
          {standup.questions.map((q) => {
            const question = questions[q];

            return (
              <Checkbox
                key={question.questionId}
                className="p-1"
                label={question.content}
                value={questionFilter[question.questionId]}
                onChange={(e) =>
                  handleQuestionFilterChange(e, question.questionId)
                }
              />
            );
          })}
          {archivedQuestions.map((question) => (
            <Checkbox
              key={question.questionId}
              className="p-1"
              label={`[Archived] ${question.content}`}
              value={questionFilter[question.questionId]}
              onChange={(e) =>
                handleQuestionFilterChange(e, question.questionId)
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ParticipantFilters;

export const setRestrictions = ({ state, action }) => {
  return {
    ...state,
    isLoading: false,
    user: {
      ...state.user,
      userRestrictionEnabled: action.payload.userRestrictionEnabled,
      userAllowlist: action.payload.userAllowlist,
      additionalAdmins: action.payload.additionalAdmins,
    },
  };
};

import { onboarded as onboardedAction } from 'actions/auth';
import { postRequest } from 'utils/fetch';

import { createThunk } from '../create-thunk';

export const onboarded = createThunk({
  handler: async ({ args }) => {
    await postRequest('/user/onboarded', { skipped: args.skipped }, true);

    return {};
  },
  onSuccess: [onboardedAction],
  onFailure: [onboardedAction],
});

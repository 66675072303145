import { useEffect } from 'react';

export const useClickOutside = (elemRef, onClick) => {
  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside);

    return () => {
      document.removeEventListener('mousedown', onClickOutside);
    };
  });

  const onClickOutside = (e) => {
    if (elemRef && elemRef.current && !elemRef.current.contains(e.target)) {
      onClick();
    }
  };
};

import React from 'react';

import { ReactComponent as LoadingSvg } from './loading.svg';

const Loading = ({
  className = 'text-indigo-500',
  height = 50,
  width = 50,
}) => {
  return <LoadingSvg className={className} height={height} width={width} />;
};

export default Loading;

import { updateStandupEnabled as updateStandupEnabledAction } from 'actions/standups';
import { setRemainingParticipants } from 'actions/auth';
import { putRequest } from 'utils/fetch';

import { createThunk } from '../create-thunk';

export const updateStandupEnabled = createThunk({
  handler: async ({ args }) => {
    const result = await putRequest(
      `/standups/${args.standupId}/enabled`,
      args.data,
      true
    );

    const remainingParticipants = result.data.remainingParticipants;
    const totalParticipants = result.data.totalParticipants;

    return {
      remainingParticipants,
      totalParticipants,
      enabled: args.data.enabled,
      standupId: args.standupId,
    };
  },
  onStart: [],
  onSuccess: [
    ({ enabled, standupId }) =>
      updateStandupEnabledAction({ enabled, standupId }),
    ({ remainingParticipants, totalParticipants }) =>
      setRemainingParticipants({ remainingParticipants, totalParticipants }),
  ],
  onFailure: [],
});

import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

import { convertTo24hour } from 'utils/convert-to-24-hour';
import { errorToast, successToast } from 'utils/toast';
import { toastMessages } from 'constants/toast-messages';
import { deleteStandup, updateStandup } from 'thunks/standups';
import { usePageTitle } from 'hooks/use-page-title';
import Container from 'components/container';
import Loading from 'components/loading';
import StandupForm from 'components/standup-form';
import StandupDelete from 'components/standup-delete';
import Subheader from 'components/subheader';

const StandupEditRoute = () => {
  usePageTitle('Edit Standup');
  const { standupId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const isTrialActive = useSelector(
    (state) =>
      !!!state.auth.subscription &&
      !!!state.auth.promo &&
      state.auth.user.isActive
  );
  const isActive = useSelector((state) => state.auth.user.isActive);
  const remainingParticipants = useSelector(
    (state) => state.auth.remainingParticipants
  );
  const totalParticipants = useSelector(
    (state) => state.auth.totalParticipants
  );
  const slackData = useSelector((state) => state.slack);
  const isStandupLoading = useSelector((state) => state.standups.isLoading);
  const byStandupId = useSelector((state) => state.standups.byStandupId);
  const questionsByStandupId = useSelector(
    (state) => state.standups.questionsByStandupId
  );

  const standup = byStandupId[standupId] || false;
  const questions = questionsByStandupId[standupId] || false;

  const handleCancel = () => {
    history.push(`/standups/${standupId}`);
  };

  const handleSubmit = (updatedStandup) => {
    const data = {
      name: updatedStandup.name.value,
      channels: [updatedStandup.channel.value.value], // Will eventually support multiple channels
      reportDays: updatedStandup.reportDays.value,
      questions: updatedStandup.questions.value,
      reportTime: {
        hour: convertTo24hour(
          updatedStandup.hour.value.value,
          updatedStandup.period.value.value
        ),
        minute: updatedStandup.minute.value.value,
      },
      reportLength: updatedStandup.useStandupCap.value
        ? updatedStandup.reportLength.value
        : 0,
      frequency: updatedStandup.frequency.value.value,
      reportReminder: updatedStandup.reportReminder.value.value,
      timezone: updatedStandup.timezone.value.value,
      useParticipantTimezone: updatedStandup.useParticipantTimezone.value,
      canEditReports: updatedStandup.canEditReports.value,
      canTagUsers: updatedStandup.canTagUsers.value,
      participants: Object.values(updatedStandup.participants.value).map(
        (participant) => participant.id
      ),
      participantsFull: Object.values(updatedStandup.participants.value),
    };

    dispatch(
      updateStandup({ standupId: standup.standupId, data }, (err, res) => {
        if (!err) {
          successToast(toastMessages.STANDUP_UPDATE_SUCCESS);
          history.push(`/standups/${standup.standupId}`);
          return;
        }

        errorToast(toastMessages.STANDUP_UPDATE_ERROR);
      })
    );
  };

  const handleDelete = () => {
    dispatch(
      deleteStandup(
        {
          standupId,
          remainingParticipants:
            remainingParticipants + standup.participants.length,
          totalParticipants: totalParticipants - standup.participants.length,
        },
        (err) => {
          if (!err) {
            successToast(toastMessages.STANDUP_DELETE_SUCCESS);
            history.push(`/standups`);
            return;
          }

          errorToast(toastMessages.STANDUP_DELETE_ERROR);
        }
      )
    );
  };

  if (
    slackData.isLoading ||
    !slackData.channels.length ||
    !slackData.users.length ||
    isStandupLoading
  ) {
    return (
      <div className="text-center min-h-screen bg-gray-50 flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8">
        <Loading className="inline text-indigo-500" width={75} height={75} />
      </div>
    );
  }

  if (!standup) return <Redirect to="/" />;

  return (
    <div>
      <Subheader title="Edit My Standup" />
      <Container>
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <StandupForm
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            channels={slackData.channels}
            users={slackData.users}
            user={user}
            standup={standup}
            questions={questions}
            isTrialActive={isTrialActive}
            isActive={isActive}
            remainingParticipants={remainingParticipants}
            isEditing
          />
        </div>
        <div className="mt-6 bg-white overflow-hidden shadow rounded-lg">
          <StandupDelete onDelete={handleDelete} />
        </div>
      </Container>
    </div>
  );
};

export default StandupEditRoute;

import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import React from 'react';

const TrialBanner = ({ user }) => {
  if (user.isActive && !user.trialEndsAt) return null;

  // Account not active
  if (!user.isActive) {
    return (
      <div className="relative bg-red-600">
        <div className="max-w-7xl mx-auto py-2 px-3 sm:px-6 lg:px-8">
          <div className="pr-16 sm:text-center sm:px-16">
            <p className="font-medium text-white">
              <span className="inline">
                Trial has ended. Add billing details to enable standups.
              </span>
              <span className="block sm:ml-2 sm:inline-block">
                <Link to="/billing" className="text-white font-bold underline">
                  Add Billing <span aria-hidden="true">&rarr;</span>
                </Link>
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  const daysRemaining = moment(user.trialEndsAt)
    .utc()
    .diff(moment().utc(), 'days', true);

  return (
    <div className="relative bg-yellow-300">
      <div className="max-w-7xl mx-auto py-2 px-3 sm:px-6 lg:px-8">
        <div className="pr-16 sm:text-center sm:px-16">
          <p className="font-medium text-yellow-900">
            <span className="inline">
              <b>
                {Math.ceil(daysRemaining)} day{daysRemaining <= 1 ? '' : 's'}{' '}
              </b>
              remaining in trial.
            </span>
            <span className="block sm:ml-2 sm:inline-block">
              <Link
                to="/billing"
                className="text-yellow-900 font-bold underline"
              >
                Add Billing <span aria-hidden="true">&rarr;</span>
              </Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TrialBanner;

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const useOnboarding = () => {
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user && !user.isOnboarded) {
      history.push('/welcome');
    }
  }, [user, history]);
};

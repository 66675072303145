import { v4 as uuid } from 'uuid';

export const getQuestionDefaults = (standupId = false, content = '') => ({
  questionId: uuid(),
  standupId: standupId,
  active: true,
  required: true,
  content,
  type: 'text',
});

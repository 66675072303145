import get from 'lodash/get';

export const formatExportParticipantReports = (
  reports,
  questions,
  allQuestions
) => {
  const results = [];

  reports.forEach((report) => {
    const reportResults = {};
    questions.forEach((question) => {
      reportResults[allQuestions[question].content] = get(
        report,
        `results.${question}`,
        '-'
      );
    });

    results.push({
      status: report.responseStatus,
      date: report.respondedAt || '',
      ...reportResults,
    });
  });

  return results;
};

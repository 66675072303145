import React, { useRef } from 'react';

import { useClickOutside } from 'hooks/use-click-outside';

const ClickOutside = ({ children, onClick }) => {
  const wrapperRef = useRef();
  useClickOutside(wrapperRef, onClick);

  return <span ref={wrapperRef}>{children}</span>;
};

export default ClickOutside;

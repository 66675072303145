import { types } from './types';

export const setRemainingParticipants = ({
  remainingParticipants,
  totalParticipants,
}) => {
  return {
    type: types.SET_REMAINING_PARTICIPANTS,
    payload: {
      remainingParticipants,
      totalParticipants,
    },
  };
};

import { Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { getLocalStorage, removeLocalStorage } from 'utils/local-storage';
import { getUser } from 'thunks/auth';
import { localStorageKeys } from 'constants/local-storage';
import Loading from 'components/loading';

const SecureRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated) {
      const token = getLocalStorage(localStorageKeys.AUTH_TOKEN);
      // If there is no token, redirect to login
      if (!token) {
        window.location.replace(process.env.REACT_APP_MARKETING_URL);
      }
      // If there is a token, attempt to fetch user
      if (token) {
        // Fetch user
        dispatch(
          getUser({ token }, (err) => {
            if (err) {
              window.location.replace(process.env.REACT_APP_MARKETING_URL);
              removeLocalStorage(localStorageKeys.AUTH_TOKEN);
            }
          })
        );
      }
    }
  }, [history, dispatch, isAuthenticated]);

  return isAuthenticated ? (
    <Route {...rest} component={Component} />
  ) : (
    <div className="text-center min-h-screen bg-gray-50 flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8">
      <Loading className="inline text-indigo-500" width={75} height={75} />
    </div>
  );
};

export default SecureRoute;

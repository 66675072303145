import React from 'react';
import ReactTooltip from 'react-tooltip';

const plans = [
  {
    name: 'Includes 5 participants',
    type: 't0',
    cap: 5,
    monthly: 10,
    yearly: 100,
  },
  {
    name: 'Includes 10 participants',
    type: 't1',
    cap: 10,
    monthly: 15,
    yearly: 150,
  },
  {
    name: 'Includes 20 participants',
    type: 't2',
    cap: 20,
    monthly: 25,
    yearly: 250,
  },
  {
    name: 'Includes 30 participants',
    type: 't3',
    cap: 30,
    monthly: 30,
    yearly: 300,
  },
  {
    name: 'Includes 40 participants',
    type: 't4',
    cap: 40,
    monthly: 39,
    yearly: 390,
  },
  {
    name: 'Includes 50 participants',
    type: 't5',
    cap: 50,
    monthly: 45,
    yearly: 450,
  },
  {
    name: 'Includes unlimited participants',
    type: 't6',
    cap: 99999,
    monthly: 70,
    yearly: 700,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const PlanSelect = ({ onPlanClick, totalParticipants }) => {
  return (
    <>
      <ReactTooltip place="left" type="dark" effect="solid" />
      <div className="relative bg-white rounded-md -space-y-px">
        {plans.map((plan, planIdx) => (
          <div
            className={classNames(
              planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
              planIdx === plans.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
              'relative border border-gray-200 p-4 flex justify-center items-center'
            )}
            key={plan.cap}
          >
            <div className="text-gray-900 font-medium w-2/5">{plan.name}</div>
            <div className="flex-grow text-center w-2/5">
              <span className="text-gray-900 font-medium">
                ${plan.monthly} / mo
              </span>{' '}
              <span className="text-gray-500">(${plan.yearly} / yr)</span>
            </div>
            <div className="w-1/5 text-right">
              <span
                {...(totalParticipants > plan.cap
                  ? {
                      'data-tip':
                        "Your current usage exceeds what's included in this plan",
                    }
                  : {})}
              >
                <button
                  type="button"
                  className={classNames(
                    'inline-flex items-center px-4 py-2 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                    totalParticipants > plan.cap && 'cursor-default opacity-40'
                  )}
                  onClick={() => {
                    if (totalParticipants > plan.cap) return;
                    onPlanClick({ type: plan.type, period: 'monthly' });
                  }}
                >
                  Select Plan
                </button>
              </span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PlanSelect;

import React, { useState } from 'react';

import { createDefaultQuestions } from 'utils/create-default-questions';
import { getChannels } from 'utils/get-channels';
import { useStandupForm } from 'hooks/use-standup-form';

import General from './general';
import Intro from './intro';
import Schedule from './schedule';
import Questions from './questions';

const WelcomeWrapper = ({
  step,
  setStep,
  channels,
  users,
  user,
  remainingParticipants,
  isTrialActive,
  onCreate,
  onSkip,
}) => {
  const [errors, setErrors] = useState({});
  const [participantsLeft, setParticipantsLeft] = useState(
    remainingParticipants || 0
  );
  const standupForm = useStandupForm(user, false, channels, users);
  const [tempQuestions, setTempQuestions] = useState(
    createDefaultQuestions(standupForm.questions.value)
  );

  const channelOptions = getChannels(channels);
  const showParticipantCount = participantsLeft !== 99999 && !isTrialActive;

  const handleParticipantChange = (users) => {
    const prevUsers = Object.keys(standupForm.participants.value).length;
    const newUsers = Object.keys(users).length;
    const difference = newUsers - prevUsers;

    // Only if not unlimited
    if (participantsLeft !== 99999 && !isTrialActive) {
      // We only care if adding a user and no room left in quota
      if (newUsers > prevUsers && participantsLeft - difference < 0) {
        // TODO: error handling?
        return;
      } else {
        // Otherwise, just update the total and change
        setParticipantsLeft(participantsLeft - difference);
      }
    }

    // Update the users
    standupForm.participants.onChange(users);
  };

  const handleFullQuestionChange = (change) => {
    if (change.type === 'delete') {
      const updatedQuestions = standupForm.questions.value.filter(
        (q) => q !== change.question.questionId
      );

      // Update the form state
      standupForm.questions.onChange(updatedQuestions);

      const {
        [change.question.questionId]: removed,
        ...remainingQuestions
      } = tempQuestions;
      setTempQuestions(remainingQuestions);
    }

    if (change.type === 'update') {
      setTempQuestions({
        ...tempQuestions,
        [change.question.questionId]: change.question,
      });
      return;
    }

    if (change.type === 'create') {
      const updatedQuestions = [
        ...standupForm.questions.value,
        change.question.questionId,
      ];

      // Update the form state
      standupForm.questions.onChange(updatedQuestions);

      setTempQuestions({
        ...tempQuestions,
        [change.question.questionId]: change.question,
      });
      return;
    }
  };

  const handleCreate = () => {
    const data = {
      ...standupForm,
      fullQuestions: tempQuestions,
    };
    setErrors({});
    onCreate(data);
  };

  const nextStep = () => {
    if (step < 4) setStep(step + 1);
  };

  const previousStep = () => {
    if (step > 1) setStep(step - 1);
  };

  return (
    <div>
      <Intro
        onNext={nextStep}
        onPrevious={previousStep}
        onSkip={onSkip}
        isShowing={step === 1}
      />
      <General
        onNext={nextStep}
        onPrevious={previousStep}
        isShowing={step === 2}
        channelOptions={channelOptions}
        standupForm={standupForm}
        errors={errors}
        setErrors={setErrors}
        users={users}
        handleParticipantChange={handleParticipantChange}
        participantsLeft={participantsLeft}
        showParticipantCount={showParticipantCount}
      />
      <Schedule
        onNext={nextStep}
        onPrevious={previousStep}
        isShowing={step === 3}
        standupForm={standupForm}
        errors={errors}
        setErrors={setErrors}
      />
      <Questions
        onNext={nextStep}
        onPrevious={previousStep}
        isShowing={step === 4}
        standupForm={standupForm}
        onCreate={handleCreate}
        errors={errors}
        setErrors={setErrors}
        handleFullQuestionChange={handleFullQuestionChange}
        tempQuestions={tempQuestions}
      />
    </div>
  );
};

export default WelcomeWrapper;

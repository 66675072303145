import React from 'react';
import ReactTooltip from 'react-tooltip';

const AvatarList = ({ users = [], max = 5 }) => {
  if (!users.length) return null;

  const slicedUsers = users.slice(0, max);
  const remaining = users.length - max;

  return (
    <>
      <ReactTooltip place="top" type="dark" effect="solid" />
      {slicedUsers.map((user) => (
        <span key={user.avatar} className="mr-2" data-tip={user.name}>
          <img
            className="inline-block h-9 w-9 rounded-full ring-2 ring-transparent"
            src={user.avatar}
            alt={user.name}
          />
        </span>
      ))}
      {remaining > 0 && (
        <span className="mr-2 inline-flex h-9 w-9 rounded-full ring-2 ring-transparent bg-gray-100 font-bold text-gray-800 text-xs justify-center align-middle items-center">
          +{remaining}
        </span>
      )}
    </>
  );
};

export default AvatarList;

import { addStandupQuestion as addStandupQuestionAction } from 'actions/standups';
import { postRequest } from 'utils/fetch';

import { createThunk } from '../create-thunk';

export const addStandupQuestion = createThunk({
  handler: async ({ args }) => {
    await postRequest(`/standups/${args.standupId}/question`, args.data, true);

    return {
      standupId: args.standupId,
      questionId: args.questionId,
      question: args.question,
    };
  },
  onStart: [],
  onSuccess: [addStandupQuestionAction],
  onFailure: [],
});

const noList = [
  'no',
  'noo',
  'nno',
  'no!',
  'nah',
  'nahh',
  'nah!',
  'none',
  'nonee',
  'none!',
  'zero',
  'nope',
  'nope!',
  'none at all',
  '-',
];

export const shouldShowBlockers = (val) => {
  if (!val) return false;

  return !noList.includes(val.toLowerCase());
};

import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import React, { useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';

import { getStandupArray } from 'utils/get-standup-array';
import { errorToast, successToast } from 'utils/toast';
import { SearchIcon } from 'components/icon';
import { toastMessages } from 'constants/toast-messages';
import { updateRestrictions } from 'thunks/auth';
import { usePageTitle } from 'hooks/use-page-title';
import Button from 'components/button';
import Container from 'components/container';
import Loading from 'components/loading';
import NoStandups from 'components/no-standups';
import UserManager from 'components/user-manager';
import StandupListItem from 'components/standup-list-item';
import Subheader from 'components/subheader';
import Toggle from 'components/toggle';

const determineUserList = (userIds, users) => {
  const userList = {};

  if (userIds.length && users.length) {
    users.forEach((user) => {
      if (userIds.includes(user.id)) {
        userList[user.id] = user;
      }
    });
  }

  return userList;
};

const AdminRoute = () => {
  usePageTitle('Admin');
  const authData = useSelector((state) => state.auth);
  const slackData = useSelector((state) => state.slack);
  const standups = useSelector((state) => state.standups);
  const [filteredStandups, setFilteredStandups] = useState([]);
  const [restricted, setRestricted] = useState(
    authData.user.userRestrictionEnabled || false
  );
  const [restrictedWhitelist, setRestrictedWhitelist] = useState({});
  const [additionalAdmins, setAdditionalAdmins] = useState({});
  const dispatch = useDispatch();
  const filterRef = useRef();

  useEffect(() => {
    if (authData.user && authData.user.isAdmin) {
      setRestrictedWhitelist(
        determineUserList(authData.user.userAllowlist, slackData.users)
      );
      setAdditionalAdmins(
        determineUserList(authData.user.additionalAdmins, slackData.users)
      );
    }
  }, [authData, slackData]);

  useEffect(() => {
    setFilteredStandups(
      getStandupArray(standups.allStandupIds, standups.byStandupId)
    );
  }, [standups.allStandupIds, standups.byStandupId]);

  const handleUpdateSettings = () => {
    const data = {
      userRestrictionEnabled: restricted,
      userAllowlist: Object.values(restrictedWhitelist).map((user) => user.id),
      additionalAdmins: Object.values(additionalAdmins).map((user) => user.id),
    };

    dispatch(
      updateRestrictions({ data }, (err, res) => {
        if (!err) {
          successToast(toastMessages.UPDATE_RESTRICTIONS_SUCCESS);
          return;
        }

        errorToast(toastMessages.UPDATE_RESTRICTIONS_ERROR);
      })
    );
  };

  if (authData.isLoading || slackData.isLoading || standups.isLoading) {
    return (
      <div className="text-center min-h-screen bg-gray-50 flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8">
        <Loading className="inline text-indigo-500" width={75} height={75} />
      </div>
    );
  }

  if (
    authData.user &&
    !authData.user.isAdmin &&
    !authData.user.isAdditionalAdmin
  ) {
    return <Redirect to="/" />;
  }

  const allStandups = getStandupArray(
    standups.allStandupIds,
    standups.byStandupId
  );

  const fuse = new Fuse(allStandups, {
    keys: ['name'],
  });

  const handleFilteredResults = (e) => {
    if (e.target.value.length < 3) {
      setFilteredStandups(allStandups);
      return;
    }

    const results = fuse.search(e.target.value);
    setFilteredStandups(results.map((result) => result.item));
  };

  const handleClearFilter = () => {
    setFilteredStandups(allStandups);
    filterRef.current.value = '';
  };

  const debouncedFilter = throttle(handleFilteredResults, 300);

  return (
    <div>
      <Subheader title="Admin" />
      <Container>
        {authData.user.isAdmin && (
          <div className="bg-white mb-6 shadow rounded-lg">
            <div className="px-6 divide-y divide-gray-200">
              <div className="py-6 md:grid md:grid-cols-4 md:gap-6">
                <div className="md:col-span-2">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Permissions
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Adjust workspace permissions
                  </p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <div className="flex items-center">
                    <Toggle value={restricted} onChange={setRestricted} />
                    <span className="ml-3">
                      <span className="text-sm font-medium text-gray-900">
                        Only allow workspace admins to create standups
                      </span>
                    </span>
                  </div>
                  <div
                    style={{ maxWidth: 500 }}
                    className="mt-2 text-gray-600 text-xs"
                  >
                    By default, everyone can create a standup. By enabling this
                    option, only Slack workspace admins and selected users can
                    create them.
                  </div>
                  {restricted && (
                    <div className="mt-4">
                      <UserManager
                        users={slackData.users.filter((user) => !user.isAdmin)}
                        value={restrictedWhitelist}
                        onChange={setRestrictedWhitelist}
                        hint="Optional: select non-admin users here to provide access"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="py-6 md:grid md:grid-cols-4 md:gap-6">
                <div className="md:col-span-2">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Additional StandupWizard Admins
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Provides access to this admin page to users who are not
                    Slack workspace admins
                  </p>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <UserManager
                    users={slackData.users.filter((user) => !user.isAdmin)}
                    value={additionalAdmins}
                    onChange={setAdditionalAdmins}
                    hint="Note: these users will only be able to see the team standups below and not admin settings"
                  />
                </div>
              </div>
            </div>
            <div className="px-4 py-3 rounded-b-lg bg-gray-50 text-right sm:px-6">
              <Button type="primary" onClick={handleUpdateSettings}>
                Save Settings
              </Button>
            </div>
          </div>
        )}

        <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h3 className="text-sm leading-6 font-medium text-gray-900">
            {allStandups.length} Total Team Standup
            {allStandups.length === 1 ? '' : 's'}
          </h3>
          <div className="mt-3 sm:mt-0 sm:ml-4">
            <label htmlFor="search_candidate" className="sr-only">
              Search
            </label>
            <div className="flex rounded-md shadow-sm">
              <div className="relative flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  name="search_standups"
                  id="search_standups"
                  onChange={debouncedFilter}
                  className="focus:ring-indigo-500 focus:border-indigo-500 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                  placeholder="Filter standups"
                  ref={filterRef}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          {allStandups.length ? (
            <ul className="divide-y divide-gray-200">
              {filteredStandups.length ? (
                filteredStandups.map((standup) => (
                  <li key={standup.standupId}>
                    <StandupListItem
                      standup={standup}
                      channels={slackData.channels}
                      users={slackData.users}
                      showCreated
                    />
                  </li>
                ))
              ) : (
                <div className="p-4 text-center text-gray-800">
                  Your filter returned no results.{' '}
                  <button
                    className="text-indigo-600"
                    onClick={handleClearFilter}
                  >
                    Clear filter
                  </button>
                </div>
              )}
            </ul>
          ) : (
            <div className="p-4">
              <NoStandups />
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default AdminRoute;

import React, { useEffect } from 'react';

import { getLocalStorage, setLocalStorage } from 'utils/local-storage';
import { localStorageKeys } from 'constants/local-storage';

const classes = {
  active: 'bg-indigo-50 border-indigo-500 text-indigo-600',
  default: 'bg-white border-gray-300 text-gray-500',
};

const ReportView = ({ view, setView }) => {
  useEffect(() => {
    const storedView = getLocalStorage(localStorageKeys.REPORT_VIEW);
    if (storedView) setView(parseInt(storedView));
    // eslint-disable-next-line
  }, []);

  const handleClick = (newView) => {
    setLocalStorage(localStorageKeys.REPORT_VIEW, newView);
    setView(newView);
  };

  return (
    <div className="mt-2 flex">
      <button
        className={`w-1/2 text-sm p-2 border ${
          view === 1 ? classes.active : classes.default
        } rounded-tl-lg rounded-bl-lg cursor-pointer`}
        onClick={() => handleClick(1)}
      >
        Participant
      </button>
      <button
        className={`w-1/2 text-sm p-2 border ${
          view === 2 ? classes.active : classes.default
        } rounded-tr-lg rounded-br-lg cursor-pointer`}
        onClick={() => handleClick(2)}
      >
        Question
      </button>
    </div>
  );
};

export default ReportView;

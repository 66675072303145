import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import sanitize from 'sanitize-filename';

import { buildUserMap } from 'utils/build-user-map';
import { determineReportingStatus } from 'utils/determine-reporting-status';
import { enableAllQuestions } from 'utils/enable-all-questions';
import { errorToast } from 'utils/toast';
import { formatExportReports } from 'utils/format-export-reports';
import { getReportsForStandup } from 'thunks/reports';
import { postRequest } from 'utils/fetch';
import { toastMessages } from 'constants/toast-messages';
import Button from 'components/button';
import LoadingContent from 'components/loading-content';
import ReportFilters from 'components/report-filters';
import ReportResults from 'components/report-results';
import StandupStats from 'components/standup-stats';

const getParticipantFilter = (participants) => {
  const filter = {};

  participants.forEach((participant) => {
    filter[participant] = true;
  });

  return filter;
};

const StandupReports = ({ users = [], standup, questions }) => {
  const dispatch = useDispatch();
  const isReportsLoading = useSelector((state) => state.reports.isLoading);
  const reportsByStandupId = useSelector((state) => state.reports.byStandupId);
  const [view, setView] = useState(1);
  const [participantFilter, setParticipantFilter] = useState(
    getParticipantFilter(standup.participants)
  );
  const [questionFilter, setQuestionFilter] = useState(
    enableAllQuestions(questions)
  );
  const [reportDate, setReportDate] = useState(moment());

  useEffect(() => {
    dispatch(
      getReportsForStandup({
        standupId: standup.standupId,
        minDate: reportDate.startOf('day').toDate(),
        maxDate: reportDate.endOf('day').toDate(),
        date: reportDate.format('MM-DD-YY'),
      })
    );
    // eslint-disable-next-line
  }, [reportDate]);

  const handleDownloadReportData = async (reports) => {
    if (!reports) return;

    const usersInStandup = [];
    users.forEach((user) => {
      if (standup.participants.includes(user.id)) {
        usersInStandup.push(user);
      }
    });

    const reportData = formatExportReports(
      reports.reports,
      usersInStandup,
      standup.questions,
      questions
    );
    const filename = `${sanitize(standup.name).replace(
      ' ',
      '-'
    )}-${reportDate.format('MMM-D-YYYY')}.csv`.toLowerCase();

    const data = {
      data: reportData,
      filename,
    };

    try {
      const result = await postRequest(
        `/export/${standup.standupId}/reports`,
        data
      );
      fileDownload(result.data, filename);
    } catch (e) {
      errorToast(toastMessages.DOWNLOAD_ERROR);
    }
  };

  const reportData = reportsByStandupId[standup.standupId] || false;
  const userMap = buildUserMap(users);
  const stats = determineReportingStatus(
    reportData.reports || [],
    userMap,
    standup.participants
  );

  return (
    <>
      <div className="mb-5">
        <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h3 className="text-sm leading-6 font-medium text-gray-900">
            Report Results
          </h3>
          <div className="mt-3 sm:mt-0 sm:ml-4">
            <Button
              type="secondary"
              onClick={() => handleDownloadReportData(reportData)}
            >
              Download Report Data
            </Button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
        <div className="grid grid-cols-1 gap-4">
          <ReportFilters
            participantFilter={participantFilter}
            reportDate={reportDate}
            questionFilter={questionFilter}
            setParticipantFilter={setParticipantFilter}
            setReportDate={setReportDate}
            setQuestionFilter={setQuestionFilter}
            users={users}
            standup={standup}
            questions={questions}
            view={view}
            setView={setView}
          />
        </div>
        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
          <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-2 lg:gap-4">
            {isReportsLoading ? null : <StandupStats stats={stats} />}
          </div>
          <div className="bg-white overflow-hidden shadow rounded-lg">
            {isReportsLoading ? (
              <div className="flex flex-col items-center justify-center py-20 text-indigo-500">
                <div className="text-gray-800 pb-2">Loading Reports...</div>
                <div style={{ maxWidth: 80 }}>
                  <LoadingContent />
                </div>
              </div>
            ) : (
              <ReportResults
                reportData={reportData}
                standup={standup}
                questions={questions}
                questionFilter={questionFilter}
                participantFilter={participantFilter}
                users={users}
                view={view}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StandupReports;

export const addStandupQuestion = ({ state, action }) => {
  const updatedQuestions = [
    ...state.byStandupId[action.payload.standupId].questions,
    action.payload.questionId,
  ];

  return {
    ...state,
    byStandupId: {
      ...state.byStandupId,
      [action.payload.standupId]: {
        ...state.byStandupId[action.payload.standupId],
        questions: updatedQuestions,
      },
    },
    questionsByStandupId: {
      ...state.questionsByStandupId,
      [action.payload.standupId]: {
        ...state.questionsByStandupId[action.payload.standupId],
        [action.payload.questionId]: action.payload.question,
      },
    },
  };
};

const quotaMap = {
  small: process.env.REACT_APP_PARTICIPANT_CAP_SMALL,
  medium: process.env.REACT_APP_PARTICIPANT_CAP_MEDIUM,
  large: 'Unlimited',
  t0: process.env.REACT_APP_PARTICIPANT_CAP_T0,
  t1: process.env.REACT_APP_PARTICIPANT_CAP_T1,
  t2: process.env.REACT_APP_PARTICIPANT_CAP_T2,
  t3: process.env.REACT_APP_PARTICIPANT_CAP_T3,
  t4: process.env.REACT_APP_PARTICIPANT_CAP_T4,
  t5: process.env.REACT_APP_PARTICIPANT_CAP_T5,
  t6: 'Unlimited',
};

export const determineParticipantQuota = (subscription, promo = false) => {
  // Right now we only have 1 promo type
  if (promo && promo.promoType === 1) {
    return '25';
  }

  // They are on the trial
  if (!subscription) return 'Unlimited';

  return quotaMap[subscription.planType];
};

export const questionTypes = [
  {
    label: 'Select List',
    value: 'select',
  },
  {
    label: 'Text Field',
    value: 'text',
  },
];

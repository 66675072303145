import { Transition } from '@headlessui/react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LightBulbIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';

import { CloseIcon, MenuIcon } from 'components/icon';
import { logout } from 'thunks/auth';
import ClickOutside from 'components/click-outside';
import TrialBanner from 'components/trial-banner';

import Logo from 'components/logo';

import { ReactComponent as UserMenu } from './user-menu.svg';

const tabClasses = {
  active: 'bg-indigo-700 text-white rounded-md py-2 px-3 text-sm font-medium',
  inactive:
    'text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 text-sm font-medium',
  mobileActive:
    'bg-indigo-700 text-white block rounded-md py-2 px-3 text-base font-medium',
  mobileInactive:
    'text-white hover:bg-indigo-500 hover:bg-opacity-75 block rounded-md py-2 px-3 text-base font-medium',
};

const Header = ({ user }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const handleClickOutside = () => {
    if (isMenuOpen) {
      setMenuOpen(false);
    }
  };

  const handleLogout = () => {
    setMenuOpen(false);
    setMobileMenuOpen(false);
    dispatch(logout());
  };

  // Don't show header on select pages
  if (
    (location &&
      (location.pathname.includes('/oauth/') ||
        location.pathname.includes('/welcome') ||
        location.pathname.includes('/redeem'))) ||
    !user
  ) {
    return null;
  }

  return (
    <>
      <TrialBanner user={user} />
      <div className="bg-indigo-600">
        <nav className="bg-indigo-600 border-b border-indigo-300 border-opacity-25 lg:border-none">
          <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
            <div className="relative h-16 flex items-center justify-between lg:border-b lg:border-indigo-400 lg:border-opacity-25">
              <div className="px-2 flex items-center lg:px-0">
                <div className="flex-shrink-0">
                  <Link to="/">
                    <Logo className="text-white block h-8 w-8" />
                  </Link>
                </div>
                <div className="hidden lg:block lg:ml-10">
                  <div className="flex space-x-4">
                    <Link
                      to="/"
                      className={
                        location.pathname === '/' ||
                        location.pathname === '/standups'
                          ? tabClasses.active
                          : tabClasses.inactive
                      }
                    >
                      My Standups
                    </Link>

                    {(user.isAdmin || user.isAdditionalAdmin) && (
                      <Link
                        to="/admin"
                        className={
                          location.pathname === '/admin'
                            ? tabClasses.active
                            : tabClasses.inactive
                        }
                      >
                        Admin
                      </Link>
                    )}

                    <Link
                      to="/billing"
                      className={
                        location.pathname === '/billing'
                          ? tabClasses.active
                          : tabClasses.inactive
                      }
                    >
                      Billing
                    </Link>
                  </div>
                </div>
              </div>

              <div className="flex lg:hidden">
                <button
                  className="bg-indigo-600 p-2 rounded-md inline-flex items-center justify-center text-indigo-200 hover:text-white hover:bg-indigo-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon
                    className={`${
                      isMobileMenuOpen ? 'hidden' : 'block'
                    } h-6 w-6`}
                    onClick={() => setMobileMenuOpen(true)}
                  />
                  <CloseIcon
                    className={`${
                      isMobileMenuOpen ? 'block' : 'hidden'
                    } h-6 w-6`}
                    onClick={() => setMobileMenuOpen(false)}
                  />
                </button>
              </div>
              <div className="hidden lg:block lg:ml-4">
                <div className="flex items-center">
                  <a
                    target="_blank"
                    href="https://standupwizard.featurefind.io"
                    className="inline-flex items-center text-white hover:text-white hover:bg-indigo-500 hover:bg-opacity-75 rounded-md py-2 px-3 mr-2 text-sm font-medium"
                    rel="noreferrer"
                  >
                    <LightBulbIcon className="h-5 w-5 mr-2" />
                    Feature Requests
                  </a>
                  <div className="ml-3 relative flex-shrink-0">
                    <div>
                      <button
                        className="bg-indigo-600 rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white"
                        id="user-menu"
                        aria-haspopup="true"
                      >
                        <span className="sr-only">Open user menu</span>
                        <UserMenu
                          className="rounded-full h-8 w-8"
                          onClick={() => setMenuOpen(!isMenuOpen)}
                        />
                      </button>
                    </div>
                    <ClickOutside onClick={handleClickOutside}>
                      <Transition
                        show={isMenuOpen}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transition ease-in duration-75"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <div
                          className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 z-50"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="user-menu"
                        >
                          <div className="px-4 py-3">
                            <p className="text-sm font-medium text-gray-900 truncate">
                              {user.name}
                            </p>
                            <p className="text-xs text-gray-900 truncate">
                              {user.slackTeamName}
                            </p>
                          </div>
                          <div className="py-1">
                            <button
                              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                              role="menuitem"
                              onClick={handleLogout}
                            >
                              Sign out
                            </button>
                          </div>
                        </div>
                      </Transition>
                    </ClickOutside>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`${
              isMobileMenuOpen ? 'block' : 'hidden'
            } lg:hidden z-50`}
          >
            <div className="px-2 pt-2 pb-3 space-y-1">
              <Link
                to="/"
                className={
                  location.pathname === '/' || location.pathname === '/standups'
                    ? tabClasses.mobileActive
                    : tabClasses.mobileInactive
                }
              >
                My Standups
              </Link>
              {user.isAdmin && (
                <Link
                  to="/admin"
                  className={
                    location.pathname === '/admin'
                      ? tabClasses.mobileActive
                      : tabClasses.mobileInactive
                  }
                >
                  Admin
                </Link>
              )}

              <Link
                to="/billing"
                className={
                  location.pathname === '/billing'
                    ? tabClasses.mobileActive
                    : tabClasses.mobileInactive
                }
              >
                Billing
              </Link>
            </div>
            <div className="pb-3 border-t border-indigo-700">
              <div className="mt-3 px-2 space-y-1">
                <span
                  className="block cursor-pointer rounded-md py-2 px-3 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75"
                  onClick={handleLogout}
                >
                  Sign out
                </span>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;

import { XIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';

const AddSelectOptions = ({ options = [], onChange }) => {
  const [option, setOption] = useState('');

  const handleDelete = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    onChange(newOptions);
  };

  const handleAdd = () => {
    if (option.length > 0) {
      onChange([...options, option]);
      setOption('');
    }
  };

  return (
    <div className="mt-1 border border-gray-300 rounded-md shadow-sm">
      {options.length <= 10 && (
        <div className="relative flex items-center">
          <input
            type="text"
            name="addOption"
            className={`focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-transparent ${
              options.length ? 'rounded-t-md' : 'rounded-md'
            }`}
            onChange={(e) => setOption(e.target.value)}
            value={option}
          />
          <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
            <button
              className="inline-flex items-center rounded px-2 text-sm font-medium text-indigo-500"
              onClick={handleAdd}
            >
              Add Option
            </button>
          </div>
        </div>
      )}
      {!!options.length && (
        <div className="px-2 pb-2 border-t border-gray-300">
          {options.map((option, index) => (
            <div
              key={`${option}-${index}`}
              className="flex items-center justify-center mt-2 font-medium text-sm"
            >
              <span className="flex-grow text-gray-700">{option}</span>
              <button onClick={() => handleDelete(index)}>
                <XIcon className="h-4 w-4 text-gray-400 hover:text-gray-700" />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AddSelectOptions;

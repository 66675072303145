export const types = Object.freeze({
  ADD_STANDUP_QUESTION: 'standups::add-standup-question',
  DELETE_STANDUP_QUESTION: 'standups::delete-standup-question',
  REMOVE_STANDUP: 'standups::remove-standup',
  RESTORE_STANDUP_QUESTION: 'standups::restore-standup-question',
  SET_LOADING: 'standups::set-loading',
  SET_STANDUP: 'standups::set-standup',
  SET_STANDUPS: 'standups::set-standups',
  UPDATE_STANDUP: 'standups::update-standup',
  UPDATE_STANDUP_ENABLED: 'standups::update-standup-enabled',
  UPDATE_STANDUP_QUESTION: 'standups::update-standup-question',
});

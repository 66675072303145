export const getStandupDays = (reportDays) => {
  const standupDays = [];

  if (reportDays.sunday) standupDays.push('S');
  if (reportDays.monday) standupDays.push('M');
  if (reportDays.tuesday) standupDays.push('T');
  if (reportDays.wednesday) standupDays.push('W');
  if (reportDays.thursday) standupDays.push('T');
  if (reportDays.friday) standupDays.push('F');
  if (reportDays.saturday) standupDays.push('S');

  const days = standupDays.join(', ');
  if (days === 'S, M, T, W, T, F, S') return 'Every day';
  if (days === 'M, T, W, T, F') return 'Weekdays';
  return days;
};

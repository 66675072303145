import { types } from './types';

export const setUser = ({
  user,
  subscription,
  promo,
  token,
  remainingParticipants,
  totalParticipants,
}) => {
  return {
    type: types.SET_USER,
    payload: {
      user,
      subscription,
      promo,
      token,
      remainingParticipants,
      totalParticipants,
    },
  };
};

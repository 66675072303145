import axios from 'axios';

import store from 'store';

const getToken = (auth) =>
  typeof auth === 'string' ? auth : store.getState().auth.token;

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

// Catch any 401 / 403 and display auth errors
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && !error.response) {
      throw new Error('No connection to server.');
    }

    return Promise.reject(error.response);
  }
);

const apiURL = process.env.REACT_APP_API_URL;

export const getRequest = (url, auth = false) => {
  if (auth) {
    defaultHeaders['Authorization'] = `Bearer ${getToken(auth)}`;
  }

  return axios.get(`${apiURL}${url}`, {
    headers: defaultHeaders,
    withCredentials: true,
  });
};

export const postRequest = (url, data, auth = false) => {
  if (auth) {
    defaultHeaders['Authorization'] = `Bearer ${getToken(auth)}`;
  }

  return axios.post(`${apiURL}${url}`, data, {
    headers: defaultHeaders,
    withCredentials: true,
  });
};

export const putRequest = (url, data, auth = false) => {
  if (auth) {
    defaultHeaders['Authorization'] = `Bearer ${getToken(auth)}`;
  }

  return axios.put(`${apiURL}${url}`, data, {
    headers: defaultHeaders,
    withCredentials: true,
  });
};

export const deleteRequest = (url, auth = false) => {
  if (auth) {
    defaultHeaders['Authorization'] = `Bearer ${getToken(auth)}`;
  }

  return axios.delete(`${apiURL}${url}`, {
    headers: defaultHeaders,
    withCredentials: true,
  });
};

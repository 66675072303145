import { setLoading, setReports } from 'actions/reports';
import { getRequest } from 'utils/fetch';

import { createThunk } from '../create-thunk';

export const getReportsForStandup = createThunk({
  handler: async ({ args }) => {
    const result = await getRequest(
      `/standups/${args.standupId}/reports?minDate=${args.minDate}&maxDate=${args.maxDate}`,
      true
    );

    const reports = result.data.reports;

    return {
      date: args.date,
      reports,
      standupId: args.standupId,
    };
  },
  onStart: [() => setLoading(true)],
  onSuccess: [setReports],
  onFailure: [() => setLoading(false)],
});

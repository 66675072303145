import { Link } from 'react-router-dom';
import get from 'lodash/get';
import moment from 'moment';
import React from 'react';

import { responseStatuses } from 'constants/response-statuses';

const Report = ({
  questionFilter,
  questions,
  report = false,
  user = false,
  standupId,
  showIncompleteDate = false,
  participantId,
}) => {
  const renderContent = () => {
    if (report && report.responseStatus === responseStatuses.ABSENT) {
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
          Marked absent from today's standup.
        </span>
      );
    }

    if (
      !report ||
      !report.responseStatus ||
      report.responseStatus === responseStatuses.INCOMPLETE ||
      !report.results
    ) {
      return (
        <span className="text-sm text-gray-600 italic">
          Report is incomplete.
        </span>
      );
    }

    const questionsInReport = Object.keys(report.results);

    return questionsInReport.map((q) => {
      const question = questions[q];

      if (!question || !questionFilter[question.questionId]) return null;

      return (
        <>
          <h3 className="text-indigo-500 text-sm font-medium">
            {question.content}
          </h3>
          <p className="text-gray-900 text-sm mb-3 whitespace-pre-wrap">
            {get(report.results, question.questionId, '')}
          </p>
        </>
      );
    });
  };

  return (
    <div className="mb-6">
      <div className="flex items-center mb-2">
        {user && (
          <div>
            <Link to={`/standups/${standupId}/participant/${participantId}`}>
              <img
                className="inline-block h-9 w-9 rounded-full ring-2 ring-transparent hover:ring-indigo-500"
                src={user.avatar}
                alt={user.name}
              />
            </Link>
          </div>
        )}
        <div className={user ? 'ml-3' : ''}>
          {user && (
            <Link
              to={`/standups/${standupId}/participant/${participantId}`}
              className="text-sm font-bold text-gray-700 group-hover:text-gray-900 hover:text-indigo-500"
            >
              {user.name}{' '}
              {user.displayName && user.name !== user.displayName && (
                <span className="font-medium text-gray-500">
                  ({user.displayName})
                </span>
              )}
            </Link>
          )}
          <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
            {!report.responseStatus ||
            report.responseStatus === responseStatuses.INCOMPLETE
              ? `Not yet reported ${
                  report && showIncompleteDate
                    ? `for ${moment(report.createdAt).format(
                        'dddd, MMMM D, YYYY'
                      )}`
                    : ''
                }`
              : `Reported on ${moment(report.respondedAt).format(
                  'dddd, MMMM D, YYYY'
                )} at ${moment(report.respondedAt).format('h:mm a')}${
                  report.lastUpdated
                    ? ` [Last updated: ${moment(report.lastUpdated).format(
                        'MMMM D, YYYY [at] h:mm a'
                      )}]`
                    : ''
                }`}
          </p>
        </div>
      </div>
      <div className="bg-gray-50 overflow-hidden rounded-lg">
        <div className="p-4">{renderContent()}</div>
      </div>
    </div>
  );
};

export default Report;

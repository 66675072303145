import React from 'react';

import { get12HourTime } from 'utils/get-12-hour-time';
import { getChannelName } from 'utils/get-channel-name';
import { getStandupDays } from 'utils/get-standup-days';

const StandupSummary = ({ standup, slackData }) => {
  return (
    <dl className="p-4 grid grid-cols-1 gap-5 sm:grid-cols-4">
      <div>
        <dd className="text-md font-bold text-gray-900 truncate">
          {getStandupDays(standup.reportDays)}
        </dd>
        <dt className="text-sm font-medium text-gray-500 truncate">
          Report Days
        </dt>
      </div>
      <div>
        <dd className="text-md font-bold text-gray-900 truncate">
          {get12HourTime(standup.reportTime.hour, standup.reportTime.minute)}
        </dd>
        <dt className="text-sm font-medium text-gray-500 truncate">
          Report Time
        </dt>
      </div>
      <div>
        <dd className="text-md font-bold text-gray-900 truncate">
          {standup.participants.length}
        </dd>
        <dt className="text-sm font-medium text-gray-500 truncate">
          Participant(s)
        </dt>
      </div>
      <div>
        <dd className="text-md font-bold text-gray-900 truncate">
          #{getChannelName(slackData.channels, standup.channels[0])}
        </dd>
        <dt className="text-sm font-medium text-gray-500 truncate">
          Broadcast Channel
        </dt>
      </div>
    </dl>
  );
};

export default StandupSummary;

import { setRemainingParticipants } from 'actions/auth';
import { setLoading, setStandup } from 'actions/standups';
import { postRequest } from 'utils/fetch';

import { createThunk } from '../create-thunk';

export const addStandup = createThunk({
  handler: async ({ args }) => {
    const result = await postRequest('/standups', args.data, true);

    const standup = result.data.standup;
    const questions = result.data.questions;
    const remainingParticipants = result.data.remainingParticipants;
    const totalParticipants = result.data.totalParticipants;

    return {
      standup,
      questions,
      remainingParticipants,
      totalParticipants,
    };
  },
  onStart: [() => setLoading(true)],
  onSuccess: [
    () => setLoading(false),
    setStandup,
    ({ remainingParticipants, totalParticipants }) =>
      setRemainingParticipants({ remainingParticipants, totalParticipants }),
  ],
  onFailure: [() => setLoading(false)],
});

const planDetailMap = {
  // Legacy
  small: {
    title: 'Small',
    cost: '$10/mo ($100/yr)',
  },
  medium: {
    title: 'Medium',
    cost: '$25/mo ($250/yr)',
  },
  large: {
    title: 'Large',
    cost: '$45/mo ($450/yr)',
  },
  // Latest
  t0: {
    title: 'Includes 5 Participants',
    cost: '$10/mo ($100/yr)',
  },
  t1: {
    title: 'Includes 10 Participants',
    cost: '$15/mo ($150/yr)',
  },
  t2: {
    title: 'Includes 20 Participants',
    cost: '$25/mo ($250/yr)',
  },
  t3: {
    title: 'Includes 30 Participants',
    cost: '$30/mo ($300/yr)',
  },
  t4: {
    title: 'Includes 40 Participants',
    cost: '$39/mo ($390/yr)',
  },
  t5: {
    title: 'Includes 50 Participants',
    cost: '$45/mo ($450/yr)',
  },
  t6: {
    title: 'Includes unlimited Participants',
    cost: '$70/mo ($700/yr)',
  },
};

export const getPlanDetails = (subscription) => {
  return planDetailMap[subscription.planType];
};

import React from 'react';

import { CloseIcon } from 'components/icon';

const Participant = ({ name, onClick }) => {
  return (
    <span className="inline-flex rounded-full items-center py-0.5 pl-2 pr-1 mr-1 mb-2 text-sm font-medium bg-indigo-100 text-indigo-700">
      {name}
      <button
        type="button"
        className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
        onClick={onClick}
      >
        <span className="sr-only">Remove</span>
        <CloseIcon className="h-3 w-3" />
      </button>
    </span>
  );
};

export default Participant;

import { responseStatuses } from 'constants/response-statuses';

export const determineReportingStatus = (reports, userMap, participants) => {
  const stats = {
    incomplete: [],
    absent: [],
  };
  const normalizedReports = {};

  if (!Object.keys(userMap).length) return stats;

  for (let i = 0; i < reports.length; i++) {
    normalizedReports[reports[i].slackUserId] = reports[i];
  }

  for (let i = 0; i < participants.length; i++) {
    const user = userMap[participants[i]];

    if (user) {
      if (normalizedReports[participants[i]]) {
        const report = normalizedReports[participants[i]];
        if (report.responseStatus === responseStatuses.ABSENT) {
          stats.absent.push({
            name: user.name,
            avatar: user.avatar,
          });
        } else if (report.responseStatus === responseStatuses.INCOMPLETE) {
          stats.incomplete.push({
            name: user.name,
            avatar: user.avatar,
          });
        }
      } else {
        stats.incomplete.push({
          name: user.name,
          avatar: user.avatar,
        });
      }
    }
  }

  return stats;
};

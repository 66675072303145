import { useSelector } from 'react-redux';
import React from 'react';

import Header from 'components/header';

const Layout = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  return (
    <div className="min-h-screen bg-gray-100">
      <Header user={user} />
      {children}
    </div>
  );
};

export default Layout;

import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import React from 'react';

import { addStandup } from 'thunks/standups';
import { convertTo24hour } from 'utils/convert-to-24-hour';
import { errorToast, successToast } from 'utils/toast';
import { getStandupDays } from 'utils/get-standup-days';
import { getChannelName } from 'utils/get-channel-name';
import { toastMessages } from 'constants/toast-messages';
import { usePageTitle } from 'hooks/use-page-title';
import Container from 'components/container';
import Loading from 'components/loading';
import StandupForm from 'components/standup-form';
import Subheader from 'components/subheader';

const CreateRoute = () => {
  usePageTitle('Create New Standup');
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const allStandupIds = useSelector((state) => state.standups.allStandupIds);
  const isTrialActive = useSelector(
    (state) =>
      !!!state.auth.subscription &&
      !!!state.auth.promo &&
      state.auth.user.isActive
  );
  const remainingParticipants = useSelector(
    (state) => state.auth.remainingParticipants
  );
  const slack = useSelector((state) => state.slack);
  const standupsLoading = useSelector((state) => state.standups.isLoading);
  const isActive = useSelector((state) => state.auth.user.isActive);

  const handleCancel = () => {
    history.push('/standups');
  };

  const handleSubmit = (standup) => {
    const data = {
      name: standup.name.value,
      channels: [standup.channel.value.value], // Will eventually support multiple channels
      reportDays: standup.reportDays.value,
      reportTime: {
        hour: convertTo24hour(
          standup.hour.value.value,
          standup.period.value.value
        ),
        minute: standup.minute.value.value,
      },
      reportLength: standup.useStandupCap.value
        ? standup.reportLength.value
        : 0,
      reportReminder: standup.reportReminder.value.value,
      frequency: standup.frequency.value.value,
      timezone: standup.timezone.value.value,
      useParticipantTimezone: standup.useParticipantTimezone.value,
      canEditReports: standup.canEditReports.value,
      canTagUsers: standup.canTagUsers.value,
      participants: Object.values(standup.participants.value).map(
        (participant) => participant.id
      ),
      participantsFull: Object.values(standup.participants.value),
      questions: standup.questions.value,
      fullQuestions: standup.fullQuestions,
      messageData: {
        name: standup.name.value,
        participants: Object.values(standup.participants.value).length,
        time: `${standup.hour.value.value}:${
          standup.minute.value.value === 0 ? '00' : standup.minute.value.value
        } ${standup.period.value.value}`,
        days: getStandupDays(standup.reportDays.value),
        channel: `#${getChannelName(
          slack.channels,
          standup.channel.value.value
        )}`,
      },
    };

    dispatch(
      addStandup({ data }, (err, res) => {
        if (!err) {
          if (allStandupIds.length > 0) {
            successToast(toastMessages.STANDUP_CREATE_SUCCESS);
            history.push(`/standups/${res.standup.standupId}`);
            return;
          }

          history.push(`/standups/${res.standup.standupId}?success=true`);
          return;
        }

        errorToast(toastMessages.STANDUP_CREATE_ERROR);
      })
    );
  };

  if (
    !user ||
    slack.isLoading ||
    !slack.channels.length ||
    !slack.users.length ||
    standupsLoading
  ) {
    return (
      <div className="text-center min-h-screen bg-gray-50 flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8">
        <Loading className="inline text-indigo-500" width={75} height={75} />
      </div>
    );
  }

  if (!isActive) {
    return <Redirect to="/billing" />;
  }

  return (
    <div>
      <Subheader title="Create New Standup" />
      <Container>
        <StandupForm
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          channels={slack.channels}
          users={slack.users}
          user={user}
          remainingParticipants={remainingParticipants}
          isTrialActive={isTrialActive}
          isActive={isActive}
        />
      </Container>
    </div>
  );
};

export default CreateRoute;

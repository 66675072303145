import { Transition } from '@headlessui/react';
import React from 'react';

import Button from 'components/button';
import Input from 'components/input';
import Label from 'components/label';
import Select from 'components/select';
import UserManager from 'components/user-manager';

const General = ({
  onPrevious,
  onNext,
  isShowing,
  standupForm,
  errors,
  setErrors,
  channelOptions,
  users,
  handleParticipantChange,
  participantsLeft,
  showParticipantCount,
}) => {
  const handleNext = () => {
    const newErrors = {
      ...errors,
    };

    if (!standupForm.name.value.length) {
      newErrors.name = 'Please provide a standup name.';
    } else {
      delete newErrors.name;
    }

    if (standupForm.channel.value === '') {
      newErrors.channel = 'Please select a standup report channel.';
    } else {
      delete newErrors.channel;
    }

    if (!Object.values(standupForm.participants.value).length) {
      newErrors.participants =
        'Please select at least one user for the standup.';
    } else {
      delete newErrors.participants;
    }

    setErrors(newErrors);

    if (newErrors.name || newErrors.channel || newErrors.participants) {
      return;
    }

    onNext();
  };

  return (
    <Transition
      show={isShowing}
      enter="transition transform ease-in-out duration-300"
      enterFrom="translate-x-96 opacity-0"
      enterTo="translate-x-0 opacity-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div>
        <div className="py-10">
          <p className="text-gray-600 text-md leading-7 mb-10">
            Let's start by naming your new standup, deciding who you want to
            participate, and where you want to post report results.
          </p>
          <div className="space-y-6">
            <div className="grid grid-cols-3 gap-6">
              <div className="sm:col-span-2">
                <Label text="Standup Name" />
                <Input
                  hint="This will be displayed to the user."
                  value={standupForm.name.value}
                  onChange={standupForm.name.onChange}
                  error={errors.name}
                />
              </div>

              <div className="sm:col-span-2">
                <Select
                  name="channel"
                  label="Broadcast Channel"
                  className="max-w-xs"
                  options={channelOptions}
                  placeholder="Select channel"
                  filter="Search channels"
                  hint="Participant reports will be sent to this channel. If you'd like to use a private channel, you must invite StandupWizard to it first."
                  value={standupForm.channel.value}
                  onChange={standupForm.channel.onChange}
                  error={errors.channel}
                />
              </div>

              <div className="sm:col-span-2">
                <Label text="Participants" />
                <UserManager
                  users={users}
                  value={standupForm.participants.value}
                  onChange={handleParticipantChange}
                  error={errors.participants}
                  hint="Users selected here will be participants in this standup."
                />
                {showParticipantCount && (
                  <p className="mt-4 p-2 rounded-md text-sm text-indigo-900 inline-block bg-indigo-50">
                    <b>{participantsLeft}</b> participant spots remaining on
                    current team plan.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <Button type="primary" onClick={handleNext}>
            Next Step
          </Button>
          <Button type="tertiary" className="ml-2" onClick={onPrevious}>
            Back
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default General;

export const setParticipantReports = ({ state, action }) => {
  const { participantId, reports } = action.payload;

  return {
    ...state,
    isLoading: false,
    byParticipantId: {
      ...state.byParticipantId,
      [participantId]: reports,
    },
  };
};

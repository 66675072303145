export const setUser = ({ state, action }) => {
  return {
    ...state,
    isAuthenticated: true,
    subscription: action.payload.subscription,
    token: action.payload.token,
    remainingParticipants: action.payload.remainingParticipants,
    totalParticipants: action.payload.totalParticipants,
    user: action.payload.user,
    ...(action.payload.promo ? { promo: action.payload.promo } : {}),
  };
};

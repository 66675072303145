import clsx from 'clsx';
import React from 'react';

const Checkbox = ({
  className = '',
  name,
  label,
  sublabel = false,
  value,
  onChange,
}) => {
  return (
    <div className={clsx('relative flex items-start', className)}>
      <div className="flex items-center h-5">
        <input
          id={name}
          name={name}
          type="checkbox"
          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
          onChange={onChange}
          checked={value}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={name} className="font-medium text-gray-700">
          {label}
        </label>
        {sublabel && <p className="text-gray-500">{sublabel}</p>}
      </div>
    </div>
  );
};

export default Checkbox;

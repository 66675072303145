import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';

import { getRequest } from 'utils/fetch';
import Button from 'components/button';
import LoadingContent from 'components/loading-content';

const ParticipantScheduleModal = ({
  isOpen = false,
  onClose,
  standupId,
  userMap,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [schedules, setSchedules] = useState([]);

  useEffect(() => {
    const fetchSchedule = async () => {
      const result = await getRequest(`/standups/${standupId}/reporting`, true);
      if (result.data && result.data.schedule) {
        const scheduleData = result.data.schedule;
        setSchedules(scheduleData);
      }
      setLoading(false);
    };

    try {
      if (isOpen) {
        fetchSchedule();
      }
    } catch (e) {
      setLoading(false);
    }
  }, [isOpen, standupId]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-8 text-left shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-2xl text-left font-bold leading-6 text-gray-900"
                >
                  Participant reporting schedule
                </Dialog.Title>
                <p className="text-gray-600 text-sm mt-2">
                  Based on your current standup settings, participants will be
                  sent messages to report next at the below dates (converted to
                  the configured reporting timezone).
                </p>
                <p className="text-gray-600 text-sm mt-2">
                  <b>Please note:</b> if you recently made changes to the
                  reporting days / time and the below times are still the old
                  ones, it will take effect on the next report for each
                  participant.
                </p>
                {isLoading ? (
                  <div className="w-12 text-indigo-500 mt-10">
                    <LoadingContent />
                  </div>
                ) : (
                  <div>
                    <div className="mt-8 flow-root">
                      <div className="overflow-x-auto">
                        <div className="inline-block min-w-full w-full py-2 align-middle">
                          <table className="min-w-full w-full divide-y divide-gray-300">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                >
                                  Participant
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Next report date / time
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                              {schedules.map((schedule) => (
                                <tr key={schedule.participant}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                    {userMap[schedule.participant]?.name || ''}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {schedule.nextReportDate}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mt-8 text-right">
                  <Button onClick={onClose}>Close</Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ParticipantScheduleModal;

import { getQuestionDefaults } from 'utils/get-question-defaults';

export const createDefaultQuestions = (idList) => {
  return {
    [idList[0]]: {
      ...getQuestionDefaults(false, 'How are you feeling today?'),
      questionId: idList[0],
      type: 'select',
      options: [
        ':slightly_frowning_face: Bad',
        ':neutral_face: Neutral',
        ':smiley: Great',
      ],
    },
    [idList[1]]: {
      ...getQuestionDefaults(false, 'What did you accomplish yesterday?'),
      questionId: idList[1],
    },
    [idList[2]]: {
      ...getQuestionDefaults(false, 'What are your goals for today?'),
      questionId: idList[2],
    },
    [idList[3]]: {
      ...getQuestionDefaults(false, 'Do you have any blockers?'),
      questionId: idList[3],
      required: false,
    },
  };
};

import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';

import demoSrc from './demo.jpg';

const StandupCreated = ({ open = false, query }) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(open);

  const closeModal = () => {
    query.delete('success');
    history.replace({
      search: query.toString(),
    });
    setIsOpen(false);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-8 text-left align-middle shadow-xl transition-all">
                <img className="" src={demoSrc} alt="Standup demo" />
                <Dialog.Title
                  as="h3"
                  className="text-2xl text-center font-bold leading-6 text-gray-900 mt-8"
                >
                  Standup successfully created!
                </Dialog.Title>
                <div className="mt-4">
                  <p className="text-sm text-center text-gray-500">
                    When it comes time for each participant to report, they will
                    receive a message from StandupWizard prompting them to
                    select an appropriate action.
                  </p>
                </div>

                <div className="mt-4">
                  <p className="text-sm text-center text-gray-500">
                    Want to know when each participant will be sent the report
                    reminder message next? Click the{' '}
                    <b className="underline">
                      View participant reporting schedule
                    </b>{' '}
                    button under the standup summary at any time.
                  </p>
                </div>

                <div className="mt-8 text-center">
                  <button
                    type="button"
                    className="cursor-pointer inline-flex items-center px-6 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={closeModal}
                  >
                    Got it, thanks!
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default StandupCreated;

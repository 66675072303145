import React from 'react';

const PariticipantOption = ({ user, onClick }) => {
  // TODO: add image fallback if none
  return (
    <div
      className="text-gray-900 cursor-pointer select-none relative py-2 px-4 hover:bg-gray-100"
      onClick={onClick}
    >
      <div className="flex items-center">
        <div>
          <img
            className="inline-block h-6 w-6 rounded-full"
            src={user.avatar}
            alt={user.name}
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
            {user.name}{' '}
            {user.displayName && user.name !== user.displayName && (
              <span className="font-light text-gray-600">
                ({user.displayName})
              </span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PariticipantOption;

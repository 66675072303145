import { setRemainingParticipants } from 'actions/auth';
import { setLoading, removeStandup } from 'actions/standups';
import { deleteRequest } from 'utils/fetch';

import { createThunk } from '../create-thunk';

export const deleteStandup = createThunk({
  handler: async ({ args }) => {
    await deleteRequest(`/standups/${args.standupId}`, true);

    return {
      remainingParticipants: args.remainingParticipants,
      totalParticipants: args.totalParticipants,
      standupId: args.standupId,
    };
  },
  onStart: [() => setLoading(true)],
  onSuccess: [
    removeStandup,
    ({ remainingParticipants, totalParticipants }) =>
      setRemainingParticipants({ remainingParticipants, totalParticipants }),
  ],
  onFailure: [() => setLoading(false)],
});

import React from 'react';

const Container = ({ children }) => {
  return (
    <main>
      <div className="max-w-7xl mx-auto py-10 sm:px-6 lg:px-8">{children}</div>
    </main>
  );
};

export default Container;

import { types } from './types';

export const setData = ({ channels, users }) => {
  return {
    type: types.SET_DATA,
    payload: {
      channels,
      users,
    },
  };
};

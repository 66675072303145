import { v4 as uuid } from 'uuid';
import { useState } from 'react';
import findIndex from 'lodash/findIndex';

import { hours, minutes, periods } from 'constants/time-options';
import { timezones } from 'constants/timezone-options';
import { frequencies } from 'constants/frequency-options';
import { reminderTimes } from 'constants/reminder-times';

const defaultReportDays = {
  sunday: false,
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: false,
};

const determineDefaultReportTime = (standup) => {
  if (!standup) {
    return {
      hour: hours[9],
      minute: minutes[0],
      period: periods[0],
    };
  }

  const reportHour = standup.reportTime.hour;
  const reportMinute = standup.reportTime.minute;

  const period = reportHour >= 12 ? periods[1] : periods[0];
  const twelveHour =
    reportHour > 12 ? reportHour - 12 : reportHour === 0 ? 12 : reportHour;

  return {
    hour: hours.find((hr) => hr.value === twelveHour),
    minute: minutes.find((min) => min.value === reportMinute),
    period,
  };
};

const determineDefaultReportFrequency = (standup) => {
  if (!standup) {
    return frequencies[0];
  }

  const reportFrequency = frequencies.find(
    (f) => f.value === standup.reportFrequency
  );

  return reportFrequency ? reportFrequency : frequencies[0];
};

const determineDefaultReportReminder = (standup) => {
  if (!standup) {
    return reminderTimes[0];
  }

  const reportReminder = reminderTimes.find(
    (rt) => rt.value === standup.reportReminder
  );

  return reportReminder ? reportReminder : reminderTimes[0];
};

const determineStandupChannel = (channelId, channels) => {
  if (!channels.length) return '';

  const foundIndex = findIndex(channels, (channel) => channelId === channel.id);

  if (foundIndex !== -1) {
    const foundChannel = channels[foundIndex];
    return {
      value: foundChannel.id,
      label: `#${foundChannel.name}`,
    };
  }

  return '';
};

const determineStandupParticipants = (participantIds, users) => {
  const participants = {};

  if (participantIds.length && users.length) {
    users.forEach((user) => {
      if (participantIds.includes(user.id)) {
        participants[user.id] = user;
      }
    });
  }

  return participants;
};

const determineDefaultTimezone = (tz) => {
  if (!tz) return '';

  const foundIndex = findIndex(timezones, (timezone) => timezone.value === tz);

  if (foundIndex !== -1) {
    return timezones[foundIndex];
  }

  return '';
};

export const useStandupForm = (
  user,
  standup = false,
  channels = [],
  users = []
) => {
  const defaultReportTime = determineDefaultReportTime(standup);
  // General
  const [name, setName] = useState(standup ? standup.name : '');
  const [channel, setChannel] = useState(
    standup ? determineStandupChannel(standup.channels[0], channels) : ''
  );
  const [participants, setParticipants] = useState(
    standup ? determineStandupParticipants(standup.participants, users) : {}
  );
  // Schedule
  const [reportDays, setReportDays] = useState(
    standup ? standup.reportDays : defaultReportDays
  );
  const [hour, setHour] = useState(defaultReportTime.hour);
  const [minute, setMinute] = useState(defaultReportTime.minute);
  const [period, setPeriod] = useState(defaultReportTime.period);
  const [frequency, setFrequency] = useState(
    determineDefaultReportFrequency(standup)
  );
  const [timezone, setTimezone] = useState(
    determineDefaultTimezone(standup ? standup.timezone : user.slackTz)
  );
  const [useParticipantTimezone, setUseParticipantTimezone] = useState(
    standup ? standup.useParticipantTimezone : false
  );
  const [useStandupCap, setStandupCap] = useState(
    standup ? standup.reportLength > 0 : false
  );
  const [reportLength, setReportLength] = useState(
    standup ? standup.reportLength : 24
  );
  const [canEditReports, setCanEditReports] = useState(
    standup ? standup.canEditReports : true
  );
  const [questions, setQuestions] = useState(
    standup ? standup.questions : [uuid(), uuid(), uuid(), uuid()] // This should match the number of default questions
  );
  const [canTagUsers, setCanTagUsers] = useState(
    standup ? standup.canTagUsers : true
  );
  const [reportReminder, setReportReminder] = useState(
    determineDefaultReportReminder(standup)
  );

  return {
    // General
    name: {
      value: name,
      onChange: (e) => {
        const val = e.target.value;
        setName(val);
      },
    },
    channel: {
      value: channel,
      onChange: setChannel,
    },
    participants: {
      value: participants,
      onChange: setParticipants,
    },
    questions: {
      value: questions,
      onChange: setQuestions,
    },
    // Schedule
    reportDays: {
      value: reportDays,
      onChange: setReportDays,
    },
    hour: {
      value: hour,
      onChange: setHour,
    },
    minute: {
      value: minute,
      onChange: setMinute,
    },
    period: {
      value: period,
      onChange: setPeriod,
    },
    frequency: {
      value: frequency,
      onChange: setFrequency,
    },
    timezone: {
      value: timezone,
      onChange: setTimezone,
    },
    useParticipantTimezone: {
      value: useParticipantTimezone,
      onChange: (e) => {
        const val = e.target.checked;
        setUseParticipantTimezone(val);
      },
    },
    useStandupCap: {
      value: useStandupCap,
      onChange: setStandupCap,
    },
    canEditReports: {
      value: canEditReports,
      onChange: setCanEditReports,
    },
    reportLength: {
      value: reportLength,
      onChange: (e) => {
        const val = e.target.value;

        if (val === '') {
          setReportLength(val);
          return;
        }

        const parsedVal = parseInt(val);
        if (Number.isInteger(parsedVal) && parsedVal <= 24 && parsedVal > 0) {
          setReportLength(parsedVal);
        }
      },
    },
    reportReminder: {
      value: reportReminder,
      onChange: setReportReminder,
    },
    canTagUsers: {
      value: canTagUsers,
      onChange: setCanTagUsers,
    },
  };
};

import {
  setLoading,
  updateStandup as updateStandupAction,
} from 'actions/standups';
import { setRemainingParticipants } from 'actions/auth';
import { putRequest } from 'utils/fetch';

import { createThunk } from '../create-thunk';

export const updateStandup = createThunk({
  handler: async ({ args }) => {
    const result = await putRequest(
      `/standups/${args.standupId}`,
      args.data,
      true
    );

    const standup = result.data.standup;
    const remainingParticipants = result.data.remainingParticipants;
    const totalParticipants = result.data.totalParticipants;

    return {
      remainingParticipants,
      totalParticipants,
      standup,
    };
  },
  onStart: [() => setLoading(true)],
  onSuccess: [
    () => setLoading(false),
    updateStandupAction,
    ({ remainingParticipants, totalParticipants }) =>
      setRemainingParticipants({ remainingParticipants, totalParticipants }),
  ],
  onFailure: [() => setLoading(false)],
});

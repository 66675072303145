import { types } from './types';

export const setParticipantReports = ({ participantId, reports }) => {
  return {
    type: types.SET_PARTICIPANT_REPORTS,
    payload: {
      reports,
      participantId,
    },
  };
};

import { types } from './types';

export const updateStandupQuestion = ({ standupId, questionId, question }) => {
  return {
    type: types.UPDATE_STANDUP_QUESTION,
    payload: {
      standupId,
      questionId,
      question,
    },
  };
};

export const toastMessages = Object.freeze({
  DOWNLOAD_ERROR:
    'Failed to download data. Please try again or contact support.',
  GENERIC_ERROR: 'An error has occured.',
  STANDUP_DELETE_ERROR:
    'Failed to delete standup. Please try again or contact support.',
  STANDUP_DELETE_SUCCESS: 'Standup successfully deleted.',
  STANDUP_CREATE_ERROR:
    'Failed to create standup. Please try again or contact support.',
  STANDUP_CREATE_SUCCESS: 'Standup successfully created.',
  STANDUP_UPDATE_ERROR:
    'Failed to update standup. Please try again or contact support.',
  STANDUP_UPDATE_SUCCESS: 'Standup successfully updated.',
  STANDUP_STATUS_ERROR:
    'Failed to update standup status. Please try again or contact support.',
  STANDUP_NO_PARTICIPANTS:
    'Participant quota exceeded. Please upgrade plans or remove participants to enable.',
  UPDATE_RESTRICTIONS_SUCCESS: 'Permissions successfully updated.',
  UPDATE_RESTRICTIONS_ERROR:
    'Failed to update permissions. Please try again or contact support.',
});

import { Transition } from '@headlessui/react';
import React from 'react';

import { hours, minutes, periods } from 'constants/time-options';
import { timezones } from 'constants/timezone-options';
import { reminderTimes } from 'constants/reminder-times';
import Button from 'components/button';
import Checkbox from 'components/checkbox';
import DaySelector from 'components/day-selector';
import Label from 'components/label';
import Select from 'components/select';
import Toggle from 'components/toggle';

const Schedule = ({
  onPrevious,
  onNext,
  isShowing,
  standupForm,
  errors,
  setErrors,
}) => {
  const handleNext = () => {
    const newErrors = {
      ...errors,
    };

    if (
      standupForm.timezone.value === '' &&
      !standupForm.useParticipantTimezone.value
    ) {
      newErrors.timezone =
        'Please select a timezone or use participant timezones.';
    } else {
      delete newErrors.timezone;
    }

    const reportDays = standupForm.reportDays.value;
    if (
      !reportDays.sunday &&
      !reportDays.monday &&
      !reportDays.tuesday &&
      !reportDays.wednesday &&
      !reportDays.thursday &&
      !reportDays.friday &&
      !reportDays.saturday
    ) {
      newErrors.reportDays = 'Please select at least one reporting day.';
    } else {
      delete newErrors.reportDays;
    }

    setErrors(newErrors);

    if (newErrors.reportDays || newErrors.timezone) {
      return;
    }

    onNext();
  };

  return (
    <Transition
      show={isShowing}
      enter="transition transform ease-in-out duration-300"
      enterFrom="translate-x-96 opacity-0"
      enterTo="translate-x-0 opacity-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div>
        <div className="py-10">
          <p className="text-gray-600 text-md leading-7 mb-10">
            Next let's determine when you want your standup to run and collect
            reports from your team.
          </p>
          <div className="space-y-6">
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3">
                <Label text="Report Days of Week" />
                <DaySelector
                  value={standupForm.reportDays.value}
                  onChange={standupForm.reportDays.onChange}
                  error={errors.reportDays}
                />
              </div>

              <div className="col-span-3 sm:col-span-2">
                <Label text="Report Time" />
                <div className="grid grid-cols-12 gap-1">
                  <div className="col-span-3">
                    <Select
                      name="hour"
                      options={hours}
                      width={80}
                      value={standupForm.hour.value}
                      onChange={standupForm.hour.onChange}
                    />
                  </div>
                  <div className="col-span-1 text-center flex items-center">
                    <span className="text-2xl font-bold">:</span>
                  </div>
                  <div className="col-span-3">
                    <Select
                      name="minute"
                      options={minutes}
                      width={80}
                      value={standupForm.minute.value}
                      onChange={standupForm.minute.onChange}
                    />
                  </div>
                  <div className="col-span-3">
                    <Select
                      name="period"
                      options={periods}
                      width={90}
                      value={standupForm.period.value}
                      onChange={standupForm.period.onChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-3 sm:col-span-2">
                <Select
                  name="timezone"
                  label="Report Timezone"
                  options={timezones}
                  placeholder="Select timezone"
                  filter="Search timezones"
                  value={standupForm.timezone.value}
                  onChange={standupForm.timezone.onChange}
                  disabled={standupForm.useParticipantTimezone.value}
                />
                <div className="mt-2">
                  <Checkbox
                    name="useParticipantTimezone"
                    label="Use participant timezones"
                    value={standupForm.useParticipantTimezone.value}
                    onChange={standupForm.useParticipantTimezone.onChange}
                  />
                </div>
              </div>

              <div className="col-span-3 sm:col-span-2">
                <Label text="Limit Reporting Length" />
                <div className="mt-2">
                  <Toggle
                    value={standupForm.useStandupCap.value}
                    onChange={standupForm.useStandupCap.onChange}
                  />
                  {standupForm.useStandupCap.value && (
                    <div
                      className="mt-2 relative rounded-md shadow-sm"
                      style={{ width: 100 }}
                    >
                      <input
                        type="text"
                        name="reportLength"
                        id="reportLength"
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                        aria-describedby="standup-length"
                        value={standupForm.reportLength.value}
                        onChange={standupForm.reportLength.onChange}
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span
                          className="text-gray-500 sm:text-sm"
                          id="standup-length"
                        >
                          Hours
                        </span>
                      </div>
                    </div>
                  )}
                  <p
                    className="mt-2 text-xs text-gray-400"
                    style={{ maxWidth: 350 }}
                  >
                    Limit how long participants have to submit their report,
                    between 1 and 24 hours.
                  </p>
                </div>
              </div>

              {standupForm.useStandupCap.value && (
                <div className="col-span-3 sm:col-span-2">
                  <Select
                    name="timezone"
                    label="Participant Report Reminder"
                    options={reminderTimes}
                    placeholder="Select timezone"
                    filter="Search timezones"
                    value={standupForm.reportReminder.value}
                    onChange={standupForm.reportReminder.onChange}
                    error={errors.reportReminder}
                  />
                  <p
                    className="mt-2 text-xs text-gray-400"
                    style={{ maxWidth: 350 }}
                  >
                    Choose how long before the time limit to remind a
                    participant to report if they haven't already
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <Button type="primary" onClick={handleNext}>
            Next Step
          </Button>
          <Button type="tertiary" className="ml-2" onClick={onPrevious}>
            Back
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default Schedule;

import { useEffect } from 'react';

export const usePageTitle = (name = false, addSuffix = true) => {
  useEffect(() => {
    // Change the page name if provided
    if (name) {
      document.title = addSuffix ? `${name} - StandupWizard` : name;
    }
  }, [name, addSuffix]);
};

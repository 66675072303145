export const buildQuestionMap = (reports, users) => {
  const questionMap = {};

  for (let i = 0; i < reports.length; i++) {
    const results = reports[i].results;

    if (results) {
      Object.keys(results).forEach((question) => {
        if (questionMap[question]) {
          questionMap[question].push({
            user: reports[i].slackUserId,
            content: results[question],
          });
        } else {
          questionMap[question] = [
            {
              user: reports[i].slackUserId,
              content: results[question],
            },
          ];
        }
      });
    }
  }

  return questionMap;
};

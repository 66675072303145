import React from 'react';

import { usePageTitle } from 'hooks/use-page-title';

import GraphicSrc from './graphic.png';

const RestrictedRoute = () => {
  usePageTitle('Access Denied');
  return (
    <div className="flex flex-col min-h-screen items-center justify-center p-8">
      <div className="max-w-lg text-center">
        <img src={GraphicSrc} alt="Access Denied" className="mb-8 mx-auto" />

        <h1 className="text-3xl font-bold text-gray-900 mb-4">Access Denied</h1>
        <p className="text-gray-600 mb-4">
          Admins in your Slack workspace have limited who can access or create
          standups. Please contact them for access.
        </p>
        <a
          href={process.env.REACT_APP_MARKETING_URL}
          className="font-medium text-indigo-600 hover:text-indigo-500"
        >
          Go To Homepage &rarr;
        </a>
      </div>
    </div>
  );
};

export default RestrictedRoute;

import { reducerFactory } from 'reducers/reducer-factory';
import { types } from 'actions/auth';

import { canContact } from './can-contact';
import { onboarded } from './onboarded';
import { resetAuth } from './reset-auth';
import { setLoading } from './set-loading';
import { setRemainingParticipants } from './set-remaining-participants';
import { setRestrictions } from './set-restrictions';
import { setUser } from './set-user';

export const defaults = {
  isAuthenticated: false,
  isLoading: false,
  user: null,
  subscription: null,
  token: null,
  remainingParticipants: 0,
};

const actionMap = {
  [types.CAN_CONTACT]: canContact,
  [types.ONBOARDED]: onboarded,
  [types.RESET_AUTH]: resetAuth,
  [types.SET_LOADING]: setLoading,
  [types.SET_REMAINING_PARTICIPANTS]: setRemainingParticipants,
  [types.SET_RESTRICTIONS]: setRestrictions,
  [types.SET_USER]: setUser,
};

export default reducerFactory(actionMap, defaults);

import { Link } from 'react-router-dom';
import React from 'react';

import { usePageTitle } from 'hooks/use-page-title';

import { ReactComponent as Graphic } from './graphic.svg';

const NotFoundRoute = () => {
  usePageTitle('404 - Page Not Found');
  return (
    <div className="flex flex-col min-h-screen items-center justify-center p-8">
      <div className="max-w-lg text-center">
        <Graphic className="w-full mb-8" />
        <h1 className="text-3xl font-bold text-gray-900 mb-4">
          Page Not Found
        </h1>
        <p className="text-gray-600 mb-4">
          The page you are looking for can't be found or has been moved. If you
          think this is an error, please{' '}
          <a
            className="font-medium text-indigo-600 hover:text-indigo-500"
            href="mailto:support@standupwizard.com"
          >
            contact us
          </a>
          .
        </p>
        <Link
          to="/"
          className="font-medium text-indigo-600 hover:text-indigo-500"
        >
          Go To Homepage &rarr;
        </Link>
      </div>
    </div>
  );
};

export default NotFoundRoute;

import {
  isInclusivelyAfterDay,
  isInclusivelyBeforeDay,
  SingleDatePicker,
} from 'react-dates';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import 'react-dates/lib/css/_datepicker.css';

const DatePicker = ({ minDate = false, value, onChange }) => {
  const [focused, setFocused] = useState(false);

  return (
    <div className="single-date-picker mt-2">
      <SingleDatePicker
        date={value}
        focused={focused}
        onDateChange={onChange}
        onFocusChange={({ focused }) => setFocused(focused)}
        numberOfMonths={1}
        isOutsideRange={(day) => {
          if (minDate) {
            return (
              !isInclusivelyBeforeDay(day, moment()) ||
              !isInclusivelyAfterDay(day, minDate)
            );
          }

          return !isInclusivelyBeforeDay(day, moment());
        }}
        displayFormat="MMMM D, YYYY"
        noBorder
        readOnly
      />
    </div>
  );
};

export default DatePicker;

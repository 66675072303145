import { setLoading, setParticipantReports } from 'actions/reports';
import { getRequest } from 'utils/fetch';

import { createThunk } from '../create-thunk';

export const getReportsForStandupParticipant = createThunk({
  handler: async ({ args }) => {
    const result = await getRequest(
      `/standups/${args.standupId}/reports/${args.participantId}?minDate=${args.minDate}&maxDate=${args.maxDate}`,
      true
    );

    const reports = result.data.reports;

    return {
      reports,
      participantId: args.participantId,
    };
  },
  onStart: [() => setLoading(true)],
  onSuccess: [setParticipantReports],
  onFailure: [() => setLoading(false)],
});

import { ExclamationIcon, InformationCircleIcon } from '@heroicons/react/solid';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';

import {
  deleteStandupQuestion,
  restoreStandupQuestion,
  updateStandupQuestion as updateStandupQuestionAction,
} from 'actions/standups';
import { addStandupQuestion, updateStandupQuestion } from 'thunks/standups';
import { getChannels } from 'utils/get-channels';
import { hours, minutes, periods } from 'constants/time-options';
import { timezones } from 'constants/timezone-options';
import { frequencies } from 'constants/frequency-options';
import { useStandupForm } from 'hooks/use-standup-form';
import { validateStandupForm } from 'utils/validate-standup-form';
import { createDefaultQuestions } from 'utils/create-default-questions';
import { reminderTimes } from 'constants/reminder-times';
import Button from 'components/button';
import Checkbox from 'components/checkbox';
import DaySelector from 'components/day-selector';
import Input from 'components/input';
import Label from 'components/label';
import UserManager from 'components/user-manager';
import Select from 'components/select';
import StandupQuestions from 'components/standup-questions';
import Toggle from 'components/toggle';

const StandupForm = ({
  channels = [],
  isEditing = false,
  onCancel,
  onSubmit,
  standup = false,
  questions = [],
  user = false,
  users = [],
  remainingParticipants = 0,
  isTrialActive = false,
  isActive = true,
}) => {
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const [participantsLeft, setParticipantsLeft] = useState(
    remainingParticipants || 0
  );
  const standupForm = useStandupForm(user, standup, channels, users);
  const [tempQuestions, setTempQuestions] = useState(
    isEditing ? false : createDefaultQuestions(standupForm.questions.value)
  );
  const channelOptions = getChannels(channels);

  const handleFullQuestionChange = (change) => {
    if (change.type === 'delete') {
      if (standupForm.questions.value.length === 1 && isEditing) return;

      const updatedQuestions = standupForm.questions.value.filter(
        (q) => q !== change.question.questionId
      );

      // Update the form state
      standupForm.questions.onChange(updatedQuestions);

      if (!isEditing) {
        const { [change.question.questionId]: removed, ...remainingQuestions } =
          tempQuestions;
        setTempQuestions(remainingQuestions);
        return;
      }

      dispatch(
        updateStandupQuestion(
          {
            standupId: standup.standupId,
            questionId: change.question.questionId,
            data: {
              question: {
                ...change.question,
                active: false,
              },
              questionList: updatedQuestions,
            },
          },
          () => {
            dispatch(
              deleteStandupQuestion({
                standupId: standup.standupId,
                questionId: change.question.questionId,
                question: change.question,
              })
            );
          }
        )
      );

      return;
    }

    if (change.type === 'restore') {
      if (!isEditing) return;

      const updatedQuestions = [
        ...standupForm.questions.value,
        change.question.questionId,
      ];

      // Update the form state
      standupForm.questions.onChange(updatedQuestions);

      dispatch(
        updateStandupQuestion(
          {
            standupId: standup.standupId,
            questionId: change.question.questionId,
            data: {
              question: {
                ...change.question,
                active: true,
              },
              questionList: updatedQuestions,
            },
          },
          () => {
            dispatch(
              restoreStandupQuestion({
                standupId: standup.standupId,
                questionId: change.question.questionId,
                question: change.question,
              })
            );
          }
        )
      );

      return;
    }

    if (change.type === 'update') {
      if (!isEditing) {
        setTempQuestions({
          ...tempQuestions,
          [change.question.questionId]: change.question,
        });
        return;
      }

      dispatch(
        updateStandupQuestion(
          {
            standupId: standup.standupId,
            questionId: change.question.questionId,
            data: {
              question: change.question,
            },
          },
          () => {
            dispatch(
              updateStandupQuestionAction({
                standupId: standup.standupId,
                questionId: change.question.questionId,
                question: change.question,
              })
            );
          }
        )
      );

      return;
    }

    if (change.type === 'create') {
      const updatedQuestions = [
        ...standupForm.questions.value,
        change.question.questionId,
      ];

      // Update the form state
      standupForm.questions.onChange(updatedQuestions);

      if (!isEditing) {
        setTempQuestions({
          ...tempQuestions,
          [change.question.questionId]: change.question,
        });
        return;
      }

      dispatch(
        addStandupQuestion({
          standupId: standup.standupId,
          questionId: change.question.questionId,
          question: change.question,
          data: {
            questionList: updatedQuestions,
            question: change.question,
          },
        })
      );

      return;
    }
  };

  const handleSubmit = () => {
    // Check standup name
    const formErrors = validateStandupForm(standupForm);

    // There are errors
    if (Object.values(formErrors).length) {
      setErrors(formErrors);
      return;
    }

    setErrors({});

    const data = {
      ...standupForm,
    };

    if (!isEditing) {
      data.fullQuestions = tempQuestions;
    }

    onSubmit(data);
  };

  const handleParticipantChange = (users) => {
    const prevUsers = Object.keys(standupForm.participants.value).length;
    const newUsers = Object.keys(users).length;
    const difference = newUsers - prevUsers;

    // Only if not unlimited
    if (participantsLeft < 99999 && !isTrialActive) {
      // We only care if adding a user and no room left in quota
      if (newUsers > prevUsers && participantsLeft - difference < 0) {
        // TODO: error handling?
        return;
      } else {
        // Otherwise, just update the total and change
        setParticipantsLeft(participantsLeft - difference);
      }
    }

    // Update the users
    standupForm.participants.onChange(users);
  };

  const showParticipantCount = participantsLeft < 99999 && !isTrialActive;

  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="px-4 py-4 sm:p-6 space-y-8 divide-y divide-gray-200">
        <div>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                General
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Choose who will participate in this standup and which channel
                their results will get shared to
              </p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="space-y-6">
                <div className="grid grid-cols-3 gap-6">
                  <div className="sm:col-span-2">
                    <Label text="Standup Name" />
                    <Input
                      hint="This will be displayed to the user."
                      value={standupForm.name.value}
                      onChange={standupForm.name.onChange}
                      error={errors.name}
                    />
                  </div>

                  <div className="sm:col-span-2">
                    <Select
                      name="channel"
                      className="max-w-xs"
                      label="Broadcast Channel"
                      options={channelOptions}
                      placeholder="Select channel"
                      filter="Search channels"
                      hint="Participant reports will be sent to this channel. If you'd like to use a private channel, you must invite StandupWizard to it first."
                      value={standupForm.channel.value}
                      onChange={standupForm.channel.onChange}
                      error={errors.channel}
                    />
                  </div>

                  <div className="sm:col-span-2">
                    <Label text="Participants" />
                    <UserManager
                      users={users}
                      value={standupForm.participants.value}
                      onChange={handleParticipantChange}
                      error={errors.participants}
                      disabled={!isActive}
                      hint="Users selected here will be participants in this standup."
                    />
                    {showParticipantCount && (
                      <p className="mt-4 p-2 rounded-md text-sm text-indigo-900 inline-block bg-indigo-50">
                        <b>{participantsLeft}</b> participant spots remaining on
                        current team plan.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-8">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Schedule
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Choose which days and at what time participants of this standup
                are expected to check-in
              </p>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="space-y-6">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3">
                    <Label text="Report Days of Week" />
                    <DaySelector
                      value={standupForm.reportDays.value}
                      onChange={standupForm.reportDays.onChange}
                      error={errors.reportDays}
                    />
                  </div>

                  <div className="col-span-3 sm:col-span-2">
                    <Label text="Report Time" />
                    <div className="grid grid-cols-12 gap-1">
                      <div className="col-span-2 sm:col-span-2">
                        <Select
                          name="hour"
                          options={hours}
                          width={80}
                          value={standupForm.hour.value}
                          onChange={standupForm.hour.onChange}
                        />
                      </div>
                      <div className="col-span-1 sm:col-span-1 text-center flex justify-center items-center">
                        <span className="text-2xl font-bold">:</span>
                      </div>
                      <div className="col-span-2 sm:col-span-2">
                        <Select
                          name="minute"
                          options={minutes}
                          width={80}
                          value={standupForm.minute.value}
                          onChange={standupForm.minute.onChange}
                        />
                      </div>
                      <div className="col-span-2 sm:col-span-2">
                        <Select
                          name="period"
                          options={periods}
                          width={90}
                          value={standupForm.period.value}
                          onChange={standupForm.period.onChange}
                        />
                      </div>
                    </div>
                    {isEditing && (
                      <div className="rounded-md bg-blue-50 p-4 mt-4">
                        <div className="flex">
                          <div className="flex-shrink-0 text-blue-700">
                            <InformationCircleIcon className="h-5 w-5 text-blue-400" />
                          </div>
                          <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className="text-sm text-blue-700">
                              Changes to the reporting time will take effect the
                              next day for existing participants.
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-span-2">
                    <Select
                      name="frequency"
                      label="Frequency"
                      options={frequencies}
                      hint="This determines how often the report days selected above should repeat. For example, repeat every week will run the standup on the days selected above every single week."
                      placeholder="Select frequency"
                      value={standupForm.frequency.value}
                      onChange={standupForm.frequency.onChange}
                    />
                  </div>

                  <div className="col-span-3 sm:col-span-2">
                    <Select
                      name="timezone"
                      label="Report Timezone"
                      options={timezones}
                      placeholder="Select timezone"
                      filter="Search timezones"
                      value={standupForm.timezone.value}
                      onChange={standupForm.timezone.onChange}
                      disabled={standupForm.useParticipantTimezone.value}
                    />
                    <div className="mt-2">
                      <Checkbox
                        name="useParticipantTimezone"
                        label="Use participant timezones"
                        value={standupForm.useParticipantTimezone.value}
                        onChange={standupForm.useParticipantTimezone.onChange}
                      />
                    </div>
                  </div>

                  <div className="col-span-3 sm:col-span-2">
                    <Label text="Limit Reporting Time" />
                    <div className="mt-2">
                      <Toggle
                        value={standupForm.useStandupCap.value}
                        onChange={standupForm.useStandupCap.onChange}
                      />
                      {standupForm.useStandupCap.value && (
                        <div
                          className="mt-2 relative rounded-md shadow-sm"
                          style={{ width: 100 }}
                        >
                          <input
                            type="text"
                            name="reportLength"
                            id="reportLength"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                            aria-describedby="standup-length"
                            value={standupForm.reportLength.value}
                            onChange={standupForm.reportLength.onChange}
                          />
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span
                              className="text-gray-500 sm:text-sm"
                              id="standup-length"
                            >
                              Hour(s)
                            </span>
                          </div>
                        </div>
                      )}
                      {errors.reportLength && (
                        <p className="mt-2 text-sm text-red-600">
                          {errors.reportLength}
                        </p>
                      )}
                      <p
                        className="mt-2 text-xs text-gray-400"
                        style={{ maxWidth: 350 }}
                      >
                        Limit how long participants have to submit their report,
                        between 1 and 24 hours.
                      </p>
                    </div>
                  </div>

                  {standupForm.useStandupCap.value && (
                    <div className="col-span-3 sm:col-span-2">
                      <Select
                        name="timezone"
                        label="Participant Report Reminder"
                        options={reminderTimes}
                        placeholder="Select timezone"
                        filter="Search timezones"
                        value={standupForm.reportReminder.value}
                        onChange={standupForm.reportReminder.onChange}
                        error={errors.reportReminder}
                      />
                      <p
                        className="mt-2 text-xs text-gray-400"
                        style={{ maxWidth: 350 }}
                      >
                        Choose how long before the time limit to remind a
                        participant to report if they haven't already
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-8">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Questions
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Choose which questions each standup participant will be asked
                when it's time to check-in
              </p>
              {isEditing && (
                <div className="rounded-md bg-yellow-50 p-4 mt-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon
                        className="h-5 w-5 text-yellow-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <div className="text-sm text-yellow-700">
                        <p>
                          Updating or deleting existing questions that have
                          already collected data will impact previous reports
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="space-y-6">
                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3">
                    {errors.questions && (
                      <div className="rounded-md bg-red-50 p-4 mb-4">
                        <p className="text-sm font-medium text-red-800">
                          {errors.questions}
                        </p>
                      </div>
                    )}
                    <StandupQuestions
                      questions={standupForm.questions.value}
                      onChange={standupForm.questions.onChange}
                      onFullChange={handleFullQuestionChange}
                      allQuestions={isEditing ? questions : tempQuestions}
                      standupId={standup.standupId || 1}
                      isEditing={isEditing}
                    />
                  </div>
                  <div className="col-span-3 sm:col-span-2">
                    <Label text="Allow report editing" />
                    <div className="mt-2">
                      <Toggle
                        value={standupForm.canEditReports.value}
                        onChange={standupForm.canEditReports.onChange}
                      />
                      <p
                        className="mt-2 text-xs text-gray-400"
                        style={{ maxWidth: 350 }}
                      >
                        If enabled, participants can edit their reports after
                        posting. If there is a limit on reporting time, they can
                        only edit until then.
                      </p>
                    </div>
                  </div>
                  <div className="col-span-3 sm:col-span-2">
                    <Label text="Allow user tagging" />
                    <div className="mt-2">
                      <Toggle
                        value={standupForm.canTagUsers.value}
                        onChange={standupForm.canTagUsers.onChange}
                      />
                      <p
                        className="mt-2 text-xs text-gray-400"
                        style={{ maxWidth: 350 }}
                      >
                        If enabled, an additional field will be provided where
                        participants can select users to tag and notify with
                        their report.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 mt-6 px-4 py-4 sm:px-6">
        <div className="flex justify-end items-center">
          {!!Object.keys(errors).length && (
            <span className="mr-4 text-red-700">
              Please correct the errors above and try again
            </span>
          )}
          <Button className="mr-4" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSubmit}>
            {isEditing ? 'Save' : 'Create'} Standup
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StandupForm;

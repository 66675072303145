import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import React, { useState } from 'react';

import { addStandup } from 'thunks/standups';
import { convertTo24hour } from 'utils/convert-to-24-hour';
import { errorToast } from 'utils/toast';
import { getStandupDays } from 'utils/get-standup-days';
import { getChannelName } from 'utils/get-channel-name';
import { logout, onboarded } from 'thunks/auth';
import { toastMessages } from 'constants/toast-messages';
import { usePageTitle } from 'hooks/use-page-title';
import WelcomeHeader from 'components/welcome-header';
import WelcomeSteps from 'components/welcome-steps';
import WelcomeWrapper from 'components/welcome-wrapper';
import Loading from 'components/loading';

import bgSrc from './bg.jpg';

const WelcomeRoute = () => {
  usePageTitle('Welcome');
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const isTrialActive = useSelector(
    (state) => !!!state.auth.subscription && state.auth.user.isActive
  );
  const remainingParticipants = useSelector(
    (state) => state.auth.remainingParticipants
  );
  const slack = useSelector((state) => state.slack);
  const standupsLoading = useSelector((state) => state.standups.isLoading);
  const [step, setStep] = useState(1);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleSkip = () => {
    dispatch(onboarded({ skipped: true }));
  };

  const handleCreate = (standup) => {
    const data = {
      name: standup.name.value,
      channels: [standup.channel.value.value], // Will eventually support multiple channels
      reportDays: standup.reportDays.value,
      reportTime: {
        hour: convertTo24hour(
          standup.hour.value.value,
          standup.period.value.value
        ),
        minute: standup.minute.value.value,
      },
      reportLength: standup.useStandupCap.value
        ? standup.reportLength.value
        : 0,
      reportReminder: standup.reportReminder.value.value,
      timezone: standup.timezone.value.value,
      useParticipantTimezone: standup.useParticipantTimezone.value,
      participants: Object.values(standup.participants.value).map(
        (participant) => participant.id
      ),
      participantsFull: Object.values(standup.participants.value),
      questions: standup.questions.value,
      fullQuestions: standup.fullQuestions,
      frequency: 0,
      messageData: {
        name: standup.name.value,
        participants: Object.values(standup.participants.value).length,
        time: `${standup.hour.value.value}:${
          standup.minute.value.value === 0 ? '00' : standup.minute.value.value
        } ${standup.period.value.value}`,
        days: getStandupDays(standup.reportDays.value),
        channel: `#${getChannelName(
          slack.channels,
          standup.channel.value.value
        )}`,
      },
    };

    dispatch(
      addStandup({ data }, (err, res) => {
        if (!err) {
          dispatch(onboarded({ skipped: false }));
          history.push(`/standups/${res.standup.standupId}?success=true`);
          return;
        }

        errorToast(toastMessages.STANDUP_CREATE_ERROR);
      })
    );
  };

  const isLoading =
    !user ||
    slack.isLoading ||
    !slack.channels.length ||
    !slack.users.length ||
    standupsLoading;

  if (user && user.isOnboarded) {
    return <Redirect to="/" />;
  }

  return (
    <div className="bg-white h-screen">
      <div className="min-h-full flex">
        <div className="relative flex-1 flex flex-col justify-center py-24 px-4 overflow-hidden sm:px-6 lg:flex-none lg:w-1/2">
          <WelcomeHeader onLogout={handleLogout} />
          {isLoading ? (
            <div className="text-center h-full flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8">
              <Loading
                className="inline text-indigo-500"
                width={75}
                height={75}
              />
            </div>
          ) : (
            <div className="mx-auto w-full max-w-xl">
              <WelcomeSteps step={step} />
              <WelcomeWrapper
                step={step}
                setStep={setStep}
                channels={slack.channels}
                users={slack.users}
                user={user}
                remainingParticipants={remainingParticipants}
                isTrialActive={isTrialActive}
                onCreate={handleCreate}
                onSkip={handleSkip}
              />
            </div>
          )}
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={bgSrc}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default WelcomeRoute;

import {
  ChevronUpIcon,
  ChevronDownIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import React from 'react';

import QuestionDelete from 'components/question-delete';

const Question = ({
  question,
  onDelete,
  onEdit,
  onMove,
  isDeleteOpen,
  onDeleteClose,
  onDeleteOpen,
  isFirst,
  isLast,
  canDelete,
  index,
}) => {
  const handleMove = (direction) => {
    if ((direction === 'up' && !isFirst) || (direction === 'down' && !isLast)) {
      onMove(direction, index);
    }
  };

  const handleDelete = () => {
    onDelete(question);
  };

  const handleEdit = () => {
    onEdit(question.questionId);
  };

  return (
    <div className="border border-gray-300 rounded-md text-sm p-3 mb-2">
      <div className="relative flex justify-center items-center flex-grow">
        <div className="flex">
          <button
            type="button"
            className="inline-flex items-center p-2 focus:outline-none"
            onClick={() => handleMove('up')}
          >
            <ChevronUpIcon
              className={`h-4 w-4 ${
                isFirst
                  ? 'text-gray-200 cursor-default'
                  : 'text-gray-400 hover:text-gray-900'
              }`}
            />
          </button>
          <button
            type="button"
            className="inline-flex items-center p-2 focus:outline-none"
            onClick={() => handleMove('down')}
          >
            <ChevronDownIcon
              className={`h-4 w-4 ${
                isLast
                  ? 'text-gray-200 cursor-default'
                  : 'text-gray-400 hover:text-gray-900'
              }`}
            />
          </button>
        </div>
        <div className="flex-grow px-4 text-gray-900">{question.content}</div>
        <div className="flex">
          <button
            type="button"
            className="inline-flex items-center p-2"
            onClick={handleEdit}
          >
            <PencilIcon className="h-4 w-4 text-gray-400 hover:text-gray-900" />
          </button>
          {canDelete && (
            <span className="relative">
              <QuestionDelete
                isOpen={isDeleteOpen}
                onConfirm={handleDelete}
                onClose={onDeleteClose}
              />
              <button
                type="button"
                className="inline-flex items-center p-2"
                onClick={onDeleteOpen}
              >
                <TrashIcon className="h-4 w-4 text-red-400 hover:text-red-700" />
              </button>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Question;

import React from 'react';

const QuestionAnswers = ({
  question,
  answers,
  userMap,
  questionFilter,
  participantFilter,
}) => {
  if (!question || !answers.length || !questionFilter[question.questionId]) {
    return null;
  }

  return (
    <div className="mb-8">
      <div className="text-xl font-bold text-gray-700">{question.content}</div>
      {answers.map((answer) => {
        const user = userMap[answer.user];

        if (!participantFilter[answer.user]) return null;

        return (
          <div className="mt-2" key={`${question.content}-${answer.user}`}>
            <div className="flex items-center mb-2">
              <div>
                <img
                  className="inline-block h-5 w-5 rounded-full ring-2 ring-transparent hover:ring-indigo-500"
                  src={user.avatar}
                  alt={user.name}
                />
              </div>
              <div className="ml-2 text-sm font-bold text-gray-700">
                {user.name}
                {user.displayName && user.name !== user.displayName && (
                  <span className="font-medium text-gray-500">
                    {' '}
                    ({user.displayName})
                  </span>
                )}
              </div>
            </div>
            <div className="bg-gray-50 overflow-hidden rounded-lg">
              <div className="p-4">
                <div className="text-gray-900 text-sm whitespace-pre-wrap">
                  {answer.content}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default QuestionAnswers;

import { types } from './types';

export const setRestrictions = ({
  userRestrictionEnabled,
  userAllowlist,
  additionalAdmins,
}) => {
  return {
    type: types.SET_RESTRICTIONS,
    payload: {
      userRestrictionEnabled,
      userAllowlist,
      additionalAdmins,
    },
  };
};

import { normalize } from 'normalizr';
import get from 'lodash/get';

import { schemas } from 'schemas';
import { setLoading, setStandups } from 'actions/standups';
import { getRequest } from 'utils/fetch';

import { createThunk } from '../create-thunk';

export const getStandups = createThunk({
  handler: async ({ args }) => {
    const result = await getRequest('/standups', true);

    const normalizedData = normalize(
      result.data.standups,
      schemas.arrayOfStandups
    );

    const allStandupIds = get(normalizedData, 'result', []);
    const byStandupId = get(normalizedData, 'entities.standups', {});

    return {
      allStandupIds,
      byStandupId,
      questionsByStandupId: result.data.questions,
    };
  },
  onStart: [() => setLoading(true)],
  onSuccess: [setStandups],
  onFailure: [() => setLoading(false)],
});

import React, { Component } from 'react';
import * as Sentry from '@sentry/react';

import { ReactComponent as Graphic } from './graphic.svg';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  handleClick = () => {
    window.location.href = process.env.REACT_APP_CLIENT_URL;
  };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    Sentry.captureMessage('Error boundary error', info);
    Sentry.captureException(error);
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div className="flex flex-col min-h-screen items-center justify-center p-8">
          <div className="max-w-md text-center">
            <Graphic className="w-full mb-8" />
            <h1 className="text-3xl font-bold text-red-600 mb-4">Oops!</h1>
            <p className="text-gray-600 mb-4">
              It looks like something went wrong. We've been notified and are
              looking into it. If you need immediate help, please{' '}
              <a
                className="font-medium text-indigo-600 hover:text-indigo-500"
                href="mailto:support@standupwizard.com"
              >
                contact us
              </a>
              .
            </p>
            <button
              className="font-medium text-indigo-600 hover:text-indigo-500"
              onClick={this.handleClick}
            >
              Take Me Back To Safety &rarr;
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

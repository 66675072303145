import { types } from './types';

export const setStandup = ({ standup, questions }) => {
  return {
    type: types.SET_STANDUP,
    payload: {
      standup,
      questions,
    },
  };
};

import { Link, Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { XCircleIcon } from '@heroicons/react/solid';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';

import { getLocalStorage, removeLocalStorage } from 'utils/local-storage';
import { getUser } from 'thunks/auth';
import { localStorageKeys } from 'constants/local-storage';
import { postRequest } from 'utils/fetch';
import { useQueryParams } from 'hooks/use-query-params';
import Input from 'components/input';
import Loading from 'components/loading';
import Logo from 'components/logo';

import { ReactComponent as AppSumoLogo } from './appsumo-logo.svg';
import { ReactComponent as SlackLogo } from './slack-logo.svg';

const RedeemRoute = () => {
  const query = useQueryParams();
  const [isLoading, setLoading] = useState(true);
  const [code, setCode] = useState(query.get('code') || '');
  const [formError, setFormError] = useState(false);
  const [apiError, setAPIError] = useState(false);
  const history = useHistory();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const promo = useSelector((state) => state.auth.promo);
  const subscription = useSelector((state) => state.auth.subscription);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated) {
      const token = getLocalStorage(localStorageKeys.AUTH_TOKEN);
      // If there is no token, redirect to login
      if (!token) {
        setLoading(false);
      }
      // If there is a token, attempt to fetch user
      if (token) {
        // Fetch user
        dispatch(
          getUser({ token }, (err) => {
            if (err) {
              window.location.replace(process.env.REACT_APP_MARKETING_URL);
              removeLocalStorage(localStorageKeys.AUTH_TOKEN);
            }

            setLoading(false);
          })
        );
      }
    } else {
      setLoading(false);
    }
  }, [history, dispatch, isAuthenticated]);

  const handleRedeem = async () => {
    if (code.length === 0) {
      setFormError(true);
      return;
    }

    const data = {
      type: 1, // AppSumo
      codeId: code,
    };

    try {
      const result = await postRequest('/promo/redeem', data, true);

      if (!result) {
        setAPIError('An error has occured, please try again or contact us.');
        return;
      }

      if (get(result, 'data.success', false)) {
        setAPIError(false);
        window.location = `${process.env.REACT_APP_APP_URL}/billing?type=appsumo`;
      }
    } catch (e) {
      const error = get(e, 'data.error', false);

      if (error === 'redeem.code.invalid') {
        setAPIError('AppSumo code is either invalid or has already been used.');
      } else if (error === 'redeem.code.cannot.redeem') {
        setAPIError(
          'Cannot redeem code as your team already has an active subscription.'
        );
      } else {
        setAPIError('An error has occured, please try again or contact us.');
      }
    }
  };

  if (isLoading) {
    return (
      <div className="text-center min-h-screen bg-gray-50 flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8">
        <Loading className="inline text-indigo-500" width={75} height={75} />
      </div>
    );
  }

  if (isAuthenticated && (promo || subscription)) {
    return <Redirect to="/" />;
  }

  return (
    <div className="max-w-screen min-h-screen w-full flex items-center justify-center p-10">
      <div className="max-w-full">
        <div>
          <div className="bg-white overflow-hidden shadow rounded-lg max-w-3xl mx-auto">
            <div className="px-10 pt-10 pb-14">
              {apiError && (
                <div className="rounded-md bg-red-50 p-4 mb-5">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <XCircleIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        {apiError}
                      </h3>
                    </div>
                  </div>
                </div>
              )}
              <div className="text-center md:flex md:justify-center md:items-center">
                <span>
                  <Logo className="text-indigo-500 mx-auto" />
                </span>
                <span className="text-4xl text-gray-700 font-extrabold md:ml-6">
                  +
                </span>
                <span>
                  <AppSumoLogo
                    style={{ width: 300 }}
                    className="h-auto text-gray-800 fill-current mx-auto"
                  />
                </span>
              </div>
              <h1 className="text-center text-4xl text-gray-800 font-bold mt-10">
                Welcome Sumo-ling!
              </h1>
              {isAuthenticated ? (
                <>
                  <p className="max-w-xl mx-auto mt-4 text-center text-gray-700 leading-7">
                    On this page you will be able to easily redeem your unique
                    AppSumo code and get immediate access to all the great
                    features StandupWizard has to offer.
                  </p>
                  <div className="max-w-md mx-auto mt-10 text-center">
                    <Input
                      placeholder="Enter AppSumo code"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      error={formError}
                    />
                    <button
                      className="inline-block items-center px-4 py-2 mt-4 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 w-full hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={handleRedeem}
                    >
                      Redeem Code
                    </button>
                  </div>
                </>
              ) : (
                <div className="text-center">
                  <p className="max-w-xl mx-auto mt-4 text-center text-gray-700 leading-7">
                    On this page you will be able to easily redeem your unique
                    AppSumo code, however you must first have the Slack app
                    installed and be logged in. Click the "Add To Slack" below
                    and you will be prompted to do so. Once done, simply{' '}
                    <b
                      className="cursor-pointer"
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      refresh this page
                    </b>
                    .
                  </p>
                  <a
                    href={`${process.env.REACT_APP_BOT_URL}/install`}
                    target="_blank"
                    rel="noreferrer"
                    className="bg-white inline-flex justify-center items-center border border-gray-200 rounded-full shadow px-12 mt-10 hover:border-gray-600"
                    style={{ width: 300 }}
                  >
                    <SlackLogo className="inline" />
                    <div className="whitespace-nowrap font-extrabold text-lg text-gray-700 pr-4">
                      Add To Slack
                    </div>
                  </a>
                </div>
              )}
            </div>
          </div>
          <Link className="block mt-2 text-center text-gray-600" to="/">
            &larr; Back to dashboard
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RedeemRoute;

import { types } from './types';

export const addStandupQuestion = ({ standupId, questionId, question }) => {
  return {
    type: types.ADD_STANDUP_QUESTION,
    payload: {
      standupId,
      questionId,
      question,
    },
  };
};

import React from 'react';

import Button from 'components/button';

import { ReactComponent as Graphic } from './graphic.svg';

const NoStandups = () => {
  return (
    <div className="p-8 text-center">
      <div className="mb-4">
        <Graphic className="inline-block" />
      </div>
      <h2 className="mx-auto text-gray-800 text-2xl font-extrabold">
        No Standups
      </h2>
      <p
        style={{ maxWidth: 400 }}
        className="mb-4 mx-auto text-gray-800 text-md"
      >
        Create your first standup to get started.
      </p>
      <Button type="primary" to="/create">
        Create Standup
      </Button>
    </div>
  );
};

export default NoStandups;

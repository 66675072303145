import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import 'react-dates/initialize';

import AdminRoute from 'routes/admin';
import BillingRoute from 'routes/billing';
import CreateRoute from 'routes/create';
import ErrorBoundary from 'components/error-boundary';
import HomeRoute from 'routes/home';
import Layout from 'components/layout';
import NotFoundRoute from 'routes/not-found';
import OAuthErrorRoute from 'routes/oauth-error';
import OAuthSuccessRoute from 'routes/oauth-success';
import ParticipantRoute from 'routes/participant';
import RedeemRoute from 'routes/redeem';
import RestrictedRoute from 'routes/restricted';
import SecureRoute from 'components/secure-route';
import StandupRoute from 'routes/standup';
import StandupEditRoute from 'routes/standup-edit';
import WelcomeRoute from 'routes/welcome';

Modal.setAppElement('#root');

const App = () => {
  return (
    <div className="App">
      <ToastContainer
        autoClose={3000}
        newestOnTop={true}
        pauseOnHover
        hideProgressBar
      />
      <ErrorBoundary>
        <Layout>
          <Switch>
            <Route exact path="/oauth/error" component={OAuthErrorRoute} />
            <Route exact path="/oauth/:token" component={OAuthSuccessRoute} />
            <Route exact path="/restricted" component={RestrictedRoute} />
            <Route exact path="/redeem" component={RedeemRoute} />
            <SecureRoute exact path="/" component={HomeRoute} />
            <SecureRoute exact path="/standups" component={HomeRoute} />
            <SecureRoute exact path="/billing" component={BillingRoute} />
            <SecureRoute exact path="/admin" component={AdminRoute} />
            <SecureRoute exact path="/create" component={CreateRoute} />
            <SecureRoute exact path="/welcome" component={WelcomeRoute} />
            <SecureRoute
              exact
              path="/standups/:standupId"
              component={StandupRoute}
            />
            <SecureRoute
              exact
              path="/standups/:standupId/edit"
              component={StandupEditRoute}
            />
            <SecureRoute
              exact
              path="/standups/:standupId/participant/:participantId"
              component={ParticipantRoute}
            />
            <Route component={NotFoundRoute} />
          </Switch>
        </Layout>
      </ErrorBoundary>
    </div>
  );
};

export default App;

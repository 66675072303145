import { reducerFactory } from 'reducers/reducer-factory';
import { types } from 'actions/reports';

import { setLoading } from './set-loading';
import { setReports } from './set-reports';
import { setParticipantReports } from './set-participant-reports';

export const defaults = {
  isLoading: false,
  byStandupId: {},
  byParticipantId: {},
};

const actionMap = {
  [types.SET_LOADING]: setLoading,
  [types.SET_REPORTS]: setReports,
  [types.SET_PARTICIPANT_REPORTS]: setParticipantReports,
};

export default reducerFactory(actionMap, defaults);

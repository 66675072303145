import { Transition } from '@headlessui/react';
import React from 'react';

import { ReactComponent as Graphic } from './intro.svg';

const Intro = ({ onNext, isShowing, onSkip }) => {
  return (
    <Transition
      appear={true}
      show={isShowing}
      enter="transition transform ease-in-out duration-300"
      enterFrom="translate-x-96 opacity-0"
      enterTo="translate-x-0 opacity-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="p-12 text-left">
        <div className="mb-4 max-w-xs">
          <Graphic className="max-w-full w-full" />
        </div>
        <div className="mb-10 mx-auto max-w-xl">
          <h2 className="text-gray-800 text-3xl font-bold mb-4">
            Let's create your first standup
          </h2>
          <p className="text-gray-600 text-md leading-7">
            Standups are an effective way to keep your team in sync by gathering
            everyone's status automatically on a schedule of your choosing and
            broadcasting to the team.
          </p>
        </div>
        <div>
          <button
            className="flex items-center  mb-2 px-14 py-4 border border-transparent text-md font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={onNext}
          >
            Get Started
          </button>
          <button
            className="text-sm text-gray-400 hover:text-gray-800"
            onClick={onSkip}
          >
            Take me to the dashboard instead
          </button>
        </div>
      </div>
    </Transition>
  );
};

export default Intro;

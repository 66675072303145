import React from 'react';

const classes = {
  selected:
    'inline-flex items-center justify-center p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 mr-2 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
  notSelected:
    'inline-flex items-center justify-center p-2 border border-gray-300 rounded-full shadow-sm text-gray-700 bg-white mr-2 hover:border-indigo-700 hover:text-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
};

const DaySelector = ({ error = false, value, onChange }) => {
  const handleChange = (day) => {
    onChange({
      ...value,
      [day]: !value[day],
    });
  };

  return (
    <>
      <div className="mt-1">
        <button
          type="button"
          className={value.sunday ? classes.selected : classes.notSelected}
          onClick={() => handleChange('sunday')}
        >
          <span className="h-10 w-10 leading-none flex items-center justify-center">
            S
          </span>
        </button>
        <button
          type="button"
          className={value.monday ? classes.selected : classes.notSelected}
          onClick={() => handleChange('monday')}
        >
          <span className="h-10 w-10 leading-none flex items-center justify-center">
            M
          </span>
        </button>
        <button
          type="button"
          className={value.tuesday ? classes.selected : classes.notSelected}
          onClick={() => handleChange('tuesday')}
        >
          <span className="h-10 w-10 leading-none flex items-center justify-center">
            T
          </span>
        </button>
        <button
          type="button"
          className={value.wednesday ? classes.selected : classes.notSelected}
          onClick={() => handleChange('wednesday')}
        >
          <span className="h-10 w-10 leading-none flex items-center justify-center">
            W
          </span>
        </button>
        <button
          type="button"
          className={value.thursday ? classes.selected : classes.notSelected}
          onClick={() => handleChange('thursday')}
        >
          <span className="h-10 w-10 leading-none flex items-center justify-center">
            T
          </span>
        </button>
        <button
          type="button"
          className={value.friday ? classes.selected : classes.notSelected}
          onClick={() => handleChange('friday')}
        >
          <span className="h-10 w-10 leading-none flex items-center justify-center">
            F
          </span>
        </button>
        <button
          type="button"
          className={value.saturday ? classes.selected : classes.notSelected}
          onClick={() => handleChange('saturday')}
        >
          <span className="h-10 w-10 leading-none flex items-center justify-center">
            S
          </span>
        </button>
      </div>
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </>
  );
};

export default DaySelector;

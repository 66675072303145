import round from 'lodash/round';

import { responseStatuses } from 'constants/response-statuses';
import { shouldShowBlockers } from './should-show-blockers';

export const determineStats = (reports, participants, individual = false) => {
  if (!reports || participants === 0) return false;

  let reporting = 0;
  let blockers = 0;
  let absences = 0;

  if (!reports.length) {
    return {
      reporting,
      blockers,
      absences,
    };
  }

  for (let i = 0; i < reports.length; i++) {
    const current = reports[i];

    if (current.responseStatus !== responseStatuses.INCOMPLETE) {
      reporting++;
    }

    if (current.responseStatus === responseStatuses.ABSENT) {
      absences++;
    }

    if (current.results && shouldShowBlockers(current.results.blockers)) {
      blockers++;
    }
  }

  const divisor = individual ? reports.length : participants;

  return {
    reporting: reporting > 0 ? round((reporting / divisor) * 100, 2) : 0,
    blockers,
    absences,
  };
};

import React, { useState } from 'react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { Combobox } from '@headlessui/react';
import clsx from 'clsx';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ComboboxButton = React.forwardRef((props, ref) => {
  const newProps = {
    ...props,
    tabIndex: 0,
  };

  return (
    <button
      className="w-full flex justify-center items-center rounded-md border border-gray-300 bg-white py-2 px-3 sm:text-sm sm:leading-5 shadow-sm"
      tabIndex="0"
      ref={ref}
      {...newProps}
    >
      {props.children}
    </button>
  );
});

const Select = ({
  className = '',
  disabled = false,
  error = false,
  filter = false,
  hint = false,
  options = [],
  width = false,
  value,
  onChange,
  label = '',
  placeholder = ' ',
}) => {
  const [query, setQuery] = useState('');

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
          return option.label.toLowerCase().includes(query.toLowerCase());
        });

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  return (
    <>
      <div
        className={clsx('w-full mt-1 z-50', className)}
        style={{ ...(width ? { width } : {}) }}
      >
        <Combobox
          as="div"
          value={value}
          onChange={onChange}
          disabled={disabled}
        >
          {label && (
            <Combobox.Label className="block text-sm font-medium text-gray-700">
              {label}
            </Combobox.Label>
          )}
          <div className="relative mt-1">
            <Combobox.Button as={ComboboxButton}>
              <span className="flex-grow pr-4 text-left truncate">
                {value ? (
                  <span className="block truncate">{value.label}</span>
                ) : (
                  <span className="block truncat text-gray-400 italic">
                    {placeholder}
                  </span>
                )}
              </span>
              <span>
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Combobox.Button>
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filter && options.length > 10 && (
                <div className="p-3">
                  <Combobox.Input
                    className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                    onChange={handleQueryChange}
                    placeholder={filter}
                  />
                </div>
              )}
              {filteredOptions.length < 1 ? (
                <div className="px-4 pb-2 text-gray-500 italic">
                  No results found...
                </div>
              ) : (
                filteredOptions.map((option) => (
                  <Combobox.Option
                    key={option.value}
                    value={option}
                    className={({ active }) =>
                      classNames(
                        'relative cursor-default select-none py-2 pl-8 pr-4',
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                      )
                    }
                  >
                    {({ active, selected }) => (
                      <>
                        <span
                          className={classNames(
                            'block truncate',
                            selected && 'font-semibold'
                          )}
                        >
                          {option.label}
                        </span>

                        {selected && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 left-0 flex items-center pl-1.5',
                              active ? 'text-white' : 'text-indigo-600'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </div>
        </Combobox>
      </div>
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
      {hint && !error && <p className="mt-2 text-xs text-gray-400">{hint}</p>}
    </>
  );
};

export default Select;

import { Transition } from '@headlessui/react';
import React from 'react';

const classes = {
  circle:
    'bg-gray-200 rounded-full flex items-center justify-center font-bold text-gray-700 mx-auto',
  circleActive:
    'bg-indigo-600 rounded-full flex items-center justify-center font-bold text-white mx-auto',
  line: 'block h-1 w-full bg-gray-200 rounded',
  lineActive: 'block h-1 w-full bg-indigo-600 rounded',
};

const WelcomeSteps = ({ step }) => {
  return (
    <Transition
      appear={true}
      show={step > 1}
      enter="transition transform ease-in-out duration-300"
      enterFrom="translate-x-96 opacity-0"
      enterTo="translate-x-0 opacity-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="p-4">
        <div className="mx-auto max-w-md">
          <div className="flex">
            <div style={{ width: 75 }} className="text-center">
              <div
                style={{ height: 35, width: 35 }}
                className={step >= 2 ? classes.circleActive : classes.circle}
              >
                1
              </div>
              <div className="mt-2 text-sm text-gray-600">General</div>
            </div>

            <div className="flex-grow pt-4">
              <span className={step >= 3 ? classes.lineActive : classes.line} />
            </div>

            <div style={{ width: 75 }} className="text-center">
              <div
                style={{ height: 35, width: 35 }}
                className={step >= 3 ? classes.circleActive : classes.circle}
              >
                2
              </div>
              <div className="mt-2 text-sm text-gray-600">Schedule</div>
            </div>

            <div className="flex-grow pt-4">
              <span
                className={step === 4 ? classes.lineActive : classes.line}
              />
            </div>

            <div style={{ width: 75 }} className="text-center">
              <div
                style={{ height: 35, width: 35 }}
                className={step === 4 ? classes.circleActive : classes.circle}
              >
                3
              </div>
              <div className="mt-2 text-sm text-gray-600">Questions</div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default WelcomeSteps;

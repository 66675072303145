export const updateStandupEnabled = ({ state, action }) => {
  return {
    ...state,
    byStandupId: {
      ...state.byStandupId,
      [action.payload.standupId]: {
        ...state.byStandupId[action.payload.standupId],
        enabled: action.payload.enabled,
      },
    },
  };
};

import Modal from 'react-modal';
import React, { useState } from 'react';

import { CloseIcon, DangerIcon } from 'components/icon';
import Button from 'components/button';
import Input from 'components/input';
import Label from 'components/label';

const customStyles = {
  overlay: {
    background: 'rgba(0,0,0, 0.1)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: 'none',
  },
};

const StandupDelete = ({ onDelete }) => {
  const [isOpen, setOpen] = useState(false);
  const [confirm, setConfirm] = useState('');

  const handleClose = () => {
    setOpen(false);
    setConfirm('');
  };

  const handleConfirmChange = (e) => {
    setConfirm(e.target.value);
  };

  const handleConfirm = () => {
    if (confirm === 'YES') {
      setOpen(false);
      setConfirm('');
      onDelete();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={handleClose}
        style={customStyles}
        contentLabel="Delete Standup Modal"
      >
        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleClose}
            >
              <span className="sr-only">Close</span>
              <CloseIcon className="h-6 w-6" />
            </button>
          </div>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <DangerIcon className="h-6 w-6 text-red-600" />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
                onClick={onDelete}
              >
                Delete Standup
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500 mb-4">
                  Are you sure you want to delete this standup? All of your data
                  will be permanently removed from our servers forever and
                  cannot be undone. Type <b>YES</b> below to confirm.
                </p>
                <Input value={confirm} onChange={handleConfirmChange} />
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm disabled:opacity-50 disabled:bg-red-700"
              onClick={handleConfirm}
              disabled={confirm !== 'YES'}
            >
              Confirm Delete
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <div className="bg-white overflow-hidden shadow rounded-lg">
        <div className="px-4 py-4 sm:p-6 space-y-8 divide-y divide-gray-200">
          <div>
            <div className="md:grid md:grid-cols-3 md:gap-6">
              <div className="md:col-span-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Danger Zone
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Actions in this area cannot be undone.
                </p>
              </div>
              <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="space-y-6">
                  <div className="grid grid-cols-3 gap-6">
                    <div className="sm:col-span-2">
                      <Label text="Delete Standup and all associated data" />
                      <Button
                        className="mt-2"
                        type="danger"
                        onClick={() => setOpen(true)}
                      >
                        Delete Standup
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StandupDelete;

import { setLoading, setData } from 'actions/slack';
import { getRequest } from 'utils/fetch';

import { createThunk } from '../create-thunk';

export const getData = createThunk({
  handler: async ({ args }) => {
    const result = await getRequest('/slack/data', args.token);

    const channels = result.data.channels;
    const users = result.data.users;

    return {
      channels,
      users,
    };
  },
  onStart: [() => setLoading(true)],
  onSuccess: [() => setLoading(false), setData],
  onFailure: [() => setLoading(false)],
});

import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon, XCircleIcon } from '@heroicons/react/outline';

import { getQuestionDefaults } from 'utils/get-question-defaults';
import { questionTypes } from 'constants/question-types';
import AddSelectOptions from 'components/add-select-options';
import Button from 'components/button';
import Input from 'components/input';
import Label from 'components/label';
import Select from 'components/select';
import Toggle from 'components/toggle';

const getQuestionType = (type) => {
  return questionTypes.find((qType) => qType.value === type);
};

const QuestionEdit = ({
  isOpen,
  isCreating = false,
  onClose,
  question,
  onSave,
  standupId,
}) => {
  const [q, setQ] = useState(question);
  const [error, setError] = useState(false);

  useEffect(() => {
    setQ(question);
  }, [question]);

  useEffect(() => {
    if (isCreating) {
      setQ(getQuestionDefaults(standupId));
    }
  }, [isCreating, standupId]);

  const handleTypeChange = (type) => {
    setQ({
      ...q,
      type: type.value,
      ...(type.value === 'select' ? { options: [] } : {}),
    });
  };

  const handleContentChange = (e) => {
    const val = e.target.value;
    setQ({
      ...q,
      content: val,
    });
  };

  const handleToggleChange = (required) => {
    setQ({
      ...q,
      required,
    });
  };

  const handleOptionsChange = (options) => {
    setQ({
      ...q,
      options,
    });
  };

  const handleSave = () => {
    const newQuestion = { ...q, content: q.content.trim() };

    if (!newQuestion.content.length) {
      setError('Question text is required');
      return;
    }

    if (newQuestion.type === 'select' && newQuestion.options.length < 1) {
      setError('At least one select list option is required');
      return;
    }

    if (newQuestion.options && newQuestion.type === 'text') {
      delete newQuestion.options;
    }

    setError(false);
    onSave(newQuestion, isCreating);
    onClose();
  };

  const handleClose = () => {
    setError(false);
    setQ(false);
    onClose();
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        open={isOpen}
        onClose={handleClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
              <div>
                <div>
                  <div className="flex items-start justify-between mb-6">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {isCreating ? 'Add' : 'Edit'} Question
                    </Dialog.Title>
                    <div className="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={handleClose}
                      >
                        <span className="sr-only">Close panel</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  {q ? (
                    <div className="mt-2">
                      {error && (
                        <div className="rounded-md bg-red-50 p-4 mb-4">
                          <div className="flex">
                            <div className="flex-shrink-0">
                              <XCircleIcon
                                className="h-5 w-5 text-red-400"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-3">
                              <h3 className="text-sm font-medium text-red-800">
                                {error}
                              </h3>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="mb-4">
                        <Label text="Question Text" />
                        <Input
                          value={q.content}
                          onChange={handleContentChange}
                        />
                      </div>
                      <div>
                        <Select
                          label="Question Type"
                          name="Question Type"
                          className="max-w-full"
                          value={getQuestionType(q.type)}
                          options={questionTypes}
                          onChange={handleTypeChange}
                        />
                      </div>
                      {q.type === 'select' && (
                        <div className="mt-4">
                          <Label text="Select List Options" />
                          <AddSelectOptions
                            options={q.options}
                            onChange={handleOptionsChange}
                          />
                        </div>
                      )}
                      <div className="mt-4">
                        <Label text="Required Question" />
                        <div className="mt-1">
                          <Toggle
                            value={q.required}
                            onChange={handleToggleChange}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mt-5 sm:mt-6 text-right">
                <Button type="primary" onClick={handleSave} fullWidth>
                  Save Question
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default QuestionEdit;

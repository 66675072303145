import { Link, Redirect, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import debounce from 'lodash/debounce';

import { CogIcon } from 'components/icon';
import { errorToast } from 'utils/toast';
import { buildUserMap } from 'utils/build-user-map';
import { putRequest } from 'utils/fetch';
import { toastMessages } from 'constants/toast-messages';
import { updateStandupEnabled } from 'actions/standups';
import { usePageTitle } from 'hooks/use-page-title';
import Container from 'components/container';
import Loading from 'components/loading';
import Subheader from 'components/subheader';
import StandupSummary from 'components/standup-summary';
import ParticipantReports from 'components/participant-reports';
import Toggle from 'components/toggle';

const ParticipantRoute = () => {
  const { participantId, standupId } = useParams();
  const dispatch = useDispatch();
  const slackData = useSelector((state) => state.slack);
  const isStandupLoading = useSelector((state) => state.standups.isLoading);
  const byStandupId = useSelector((state) => state.standups.byStandupId);
  const questionsByStandupId = useSelector(
    (state) => state.standups.questionsByStandupId
  );
  const isActive = useSelector((state) => state.auth.user.isActive);
  const userMap = buildUserMap(slackData.users);
  const slackUser = userMap[participantId] || {};
  usePageTitle(slackUser.name);

  const standup = byStandupId[standupId] || false;
  const questions = questionsByStandupId[standupId] || false;

  const handleEnabledChange = async () => {
    if (!isActive) {
      return;
    }

    try {
      await putRequest(`/standups/${standup.standupId}/enabled`, {
        enabled: !standup.enabled,
      });
      dispatch(
        updateStandupEnabled({
          standupId: standup.standupId,
          enabled: !standup.enabled,
        })
      );
    } catch (e) {
      errorToast(toastMessages.STANDUP_STATUS_ERROR);
    }
  };

  const debouncedChange = debounce(handleEnabledChange, 200);

  if (slackData.isLoading || isStandupLoading) {
    return (
      <div className="text-center min-h-screen bg-gray-50 flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8">
        <Loading className="inline text-indigo-500" width={75} height={75} />
      </div>
    );
  }

  if (!standup || !questions) return <Redirect to="/" />;

  return (
    <div>
      <Subheader
        title={standup.name}
        sideActions={() => (
          <span>
            <Link
              className="rounded-full p-1 bg-gray-100 inline-block text-gray-700 align-middle mr-4 hover:bg-indigo-100"
              to={`/standups/${standup.standupId}/edit`}
              data-tip="Standup Settings"
            >
              <CogIcon className="h-6 w-6" />
            </Link>
            <span
              className="inline-block align-middle"
              data-tip={`Standup ${standup.enabled ? 'Enabled' : 'Disabled'}`}
            >
              <Toggle value={standup.enabled} onChange={debouncedChange} />
            </span>
          </span>
        )}
      />
      <Container>
        <div className="bg-white overflow-hidden mb-6 shadow rounded-lg text-center">
          <StandupSummary standup={standup} slackData={slackData} />
        </div>

        <ParticipantReports
          standup={standup}
          questions={questions}
          slackUser={slackUser}
          participantId={participantId}
        />
      </Container>
    </div>
  );
};

export default ParticipantRoute;

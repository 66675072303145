import { reducerFactory } from 'reducers/reducer-factory';
import { types } from 'actions/standups';

import { addStandupQuestion } from './add-standup-question';
import { deleteStandupQuestion } from './delete-standup-question';
import { removeStandup } from './remove-standup';
import { restoreStandupQuestion } from './restore-standup-question';
import { setLoading } from './set-loading';
import { setStandup } from './set-standup';
import { setStandups } from './set-standups';
import { updateStandup } from './update-standup';
import { updateStandupEnabled } from './update-standup-enabled';
import { updateStandupQuestion } from './update-standup-question';

export const defaults = {
  isLoading: true,
  byStandupId: {},
  allStandupIds: [],
  questionsByStandupId: {},
};

const actionMap = {
  [types.ADD_STANDUP_QUESTION]: addStandupQuestion,
  [types.DELETE_STANDUP_QUESTION]: deleteStandupQuestion,
  [types.REMOVE_STANDUP]: removeStandup,
  [types.RESTORE_STANDUP_QUESTIONS]: restoreStandupQuestion,
  [types.SET_LOADING]: setLoading,
  [types.SET_STANDUP]: setStandup,
  [types.SET_STANDUPS]: setStandups,
  [types.UPDATE_STANDUP]: updateStandup,
  [types.UPDATE_STANDUP_ENABLED]: updateStandupEnabled,
  [types.UPDATE_STANDUP_QUESTION]: updateStandupQuestion,
};

export default reducerFactory(actionMap, defaults);

export const setReports = ({ state, action }) => {
  const { date, standupId, reports } = action.payload;

  return {
    ...state,
    isLoading: false,
    byStandupId: {
      ...state.byStandupId,
      [standupId]: {
        date,
        reports,
      },
    },
  };
};

import { Transition } from '@headlessui/react';
import Fuse from 'fuse.js';
import React, { useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';

import { useClickOutside } from 'hooks/use-click-outside';
import User from './user';
import UserOption from './user-option';

const classes = {
  default:
    'cursor-pointer relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 pt-2 text-left hover:outline-none hover:shadow-outline-blue hover:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5',
  error:
    'cursor-pointer relative w-full rounded-md border border-red-300 bg-white pl-3 pr-10 pt-2 text-left hover:outline-none hover:shadow-outline-blue hover:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5',
};

const UserManager = ({
  error = false,
  hint = false,
  users = [],
  value,
  onChange,
  disabled = false,
}) => {
  const filteredUsers = users.filter((user) => !value[user.id]);
  const elemRef = useRef();
  const [isOpen, setOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(filteredUsers);

  useEffect(() => {
    setFilteredOptions(users.filter((user) => !value[user.id]));
  }, [value, users]);

  const fuse = new Fuse(filteredUsers, {
    keys: ['name'],
  });

  const handleClickOutside = () => {
    setOpen(false);
  };

  const handleUserSelect = (user) => {
    onChange({
      ...value,
      [user.id]: user,
    });
  };

  const handleRemove = (e, id) => {
    e.stopPropagation();

    const { [id]: _, ...newValue } = value;

    onChange(newValue);
  };

  const handleFilteredResults = (e) => {
    if (e.target.value.length < 3) {
      setFilteredOptions(filteredUsers);
      return;
    }

    const results = fuse.search(e.target.value);
    setFilteredOptions(results.map((result) => result.item));
  };

  const handleToggle = () => {
    if (disabled) return;
    setOpen(!isOpen);
  };

  const debouncedFilter = throttle(handleFilteredResults, 300);

  useClickOutside(elemRef, handleClickOutside);

  const selected = Object.values(value);

  return (
    <div className="mt-1 relative" ref={elemRef}>
      <div
        className={error ? classes.error : classes.default}
        onClick={handleToggle}
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleToggle();
        }}
        tabIndex={0}
      >
        {selected.length ? (
          selected.map((user) => (
            <User
              key={user.id}
              name={user.name}
              onClick={(e) => handleRemove(e, user.id)}
            />
          ))
        ) : (
          <span className="block truncat text-gray-400 italic mb-2">
            Select users
          </span>
        )}
      </div>
      <Transition
        unmount={false}
        show={isOpen}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="absolute mt-1 w-full rounded-md bg-white shadow-lg z-50 border"
      >
        {users.length > 10 && (
          <div className="p-3 pb-2">
            <input
              type="text"
              placeholder="Search users"
              onChange={debouncedFilter}
              className="w-full text-sm p-1 px-2 rounded-md border-gray-300 placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500'"
            />
          </div>
        )}
        <div className="max-h-40 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
          {filteredOptions.length ? (
            filteredOptions.map((user) => (
              <UserOption
                key={user.id}
                user={user}
                onClick={() => handleUserSelect(user)}
              />
            ))
          ) : (
            <div className="px-4 py-2 text-gray-500 italic">
              No users to select
            </div>
          )}
        </div>
      </Transition>
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
      {hint && !error && <p className="mt-2 text-xs text-gray-400">{hint}</p>}
    </div>
  );
};

export default UserManager;

export const frequencies = [
  {
    label: 'Repeat every week',
    value: 0,
  },
  {
    label: 'Repeat every 2 weeks',
    value: 1,
  },
  {
    label: 'Repeat every 3 weeks',
    value: 2,
  },
  {
    label: 'Repeat every 4 weeks',
    value: 3,
  },
];

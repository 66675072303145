export const getStandupUsers = (users, userIds) => {
  const standupUsers = [];

  for (let i = 0; i < users.length; i++) {
    const currentUser = users[i];
    if (userIds.includes(currentUser.id)) {
      standupUsers.push({
        name: currentUser.name,
        id: currentUser.id,
      });
    }
  }

  return standupUsers;
};

import { createBrowserHistory } from 'history';
import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import reducers from '../reducers';

export const history = createBrowserHistory();

const initialState = {};

const store = createStore(
  reducers,
  initialState,
  compose(applyMiddleware(thunk))
);

export default store;

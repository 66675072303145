export const removeStandup = ({ state, action }) => {
  const standupId = action.payload.standupId;
  const { [standupId]: removed, ...byStandupId } = state.byStandupId;
  const allStandupIds = state.allStandupIds.filter((id) => id !== standupId);

  return {
    ...state,
    isLoading: false,
    allStandupIds,
    byStandupId,
  };
};

import React from 'react';

const ArchivedQuestion = ({ question, onRestore, canRestore = false }) => {
  if (!question) return null;

  return (
    <div className="flex border border-gray-300 rounded-md text-sm p-3 mt-2">
      <span className="flex-grow pr-2">{question.content}</span>
      {canRestore && (
        <button
          className="text-indigo-500 hover:text-indigo-700"
          onClick={() => onRestore(question)}
        >
          Restore Question
        </button>
      )}
    </div>
  );
};

export default ArchivedQuestion;

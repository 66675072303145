import React, { useState } from 'react';

import ParticipantScheduleModal from 'components/participant-schedule-modal';

const ParticipantSchedule = ({ standupId, userMap }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <ParticipantScheduleModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        standupId={standupId}
        userMap={userMap}
      />
      <div className="border-t p-2">
        <button
          className="text-indigo-600 hover:text-indigo-800"
          onClick={() => setOpen(true)}
        >
          View participant reporting schedule
        </button>
      </div>
    </>
  );
};

export default ParticipantSchedule;

import React from 'react';

const Subheader = ({ title, sideActions = false }) => {
  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8  sm:flex sm:items-center sm:justify-between">
        <h1 className="text-lg leading-6 font-semibold text-gray-900">
          {title}
        </h1>
        <div className="mt-3 sm:mt-0 sm:ml-4">
          {sideActions && sideActions()}
        </div>
      </div>
    </header>
  );
};

export default Subheader;

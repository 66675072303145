import { Transition } from '@headlessui/react';
import React from 'react';

import Button from 'components/button';
import StandupQuestions from 'components/standup-questions';

const Questions = ({
  onPrevious,
  onCreate,
  isShowing,
  standupForm,
  errors,
  setErrors,
  handleFullQuestionChange,
  tempQuestions,
}) => {
  const handleCreate = () => {
    const newErrors = {
      ...errors,
    };

    if (!standupForm.questions.value.length) {
      newErrors.questions = 'Please add at least one report question.';
    } else {
      delete newErrors.questions;
    }

    setErrors(newErrors);

    if (newErrors.questions) {
      return;
    }

    onCreate();
  };

  return (
    <Transition
      show={isShowing}
      enter="transition transform ease-in-out duration-300"
      enterFrom="translate-x-96 opacity-0"
      enterTo="translate-x-0 opacity-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div>
        <div className="py-10">
          <p className="text-gray-600 text-md leading-7 mb-10">
            Finally, let's decide what questions you want to ask participants.
            Either keep the default ones or change them to what works best for
            your team.
          </p>
          <div className="space-y-6">
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3">
                {errors.questions && (
                  <div className="rounded-md bg-red-50 p-4 mb-4">
                    <p className="text-sm font-medium text-red-800">
                      {errors.questions}
                    </p>
                  </div>
                )}
                <StandupQuestions
                  questions={standupForm.questions.value}
                  onChange={standupForm.questions.onChange}
                  onFullChange={handleFullQuestionChange}
                  allQuestions={tempQuestions}
                  standupId={1}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Button type="primary" onClick={handleCreate}>
            Create Standup
          </Button>
          <Button type="tertiary" className="ml-2" onClick={onPrevious}>
            Back
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default Questions;

export const validateStandupForm = (standupForm) => {
  const errors = {};

  console.log({ standupForm });

  if (!standupForm.name.value.length) {
    errors.name = 'Please provide a standup name.';
  }

  if (standupForm.channel.value === '') {
    errors.channel = 'Please select a standup report channel.';
  }

  if (!Object.values(standupForm.participants.value).length) {
    errors.participants = 'Please select at least one user for the standup.';
  }

  if (
    standupForm.timezone.value === '' &&
    !standupForm.useParticipantTimezone.value
  ) {
    errors.timezone = 'Please select a timezone or use participant timezones.';
  }

  const reportDays = standupForm.reportDays.value;
  if (
    !reportDays.sunday &&
    !reportDays.monday &&
    !reportDays.tuesday &&
    !reportDays.wednesday &&
    !reportDays.thursday &&
    !reportDays.friday &&
    !reportDays.saturday
  ) {
    errors.reportDays = 'Please select at least one reporting day.';
  }

  if (!standupForm.questions.value.length) {
    errors.questions = 'Please add at least one report question.';
  }

  if (
    standupForm.useStandupCap.value &&
    (standupForm.reportLength.value === 0 ||
      standupForm.reportLength.value === '')
  ) {
    errors.reportLength = 'Please provde a length greater than zero.';
  }

  if (
    standupForm.reportReminder.value.value === 60 &&
    standupForm.useStandupCap.value === true &&
    standupForm.reportLength.value === 1
  ) {
    errors.reportReminder =
      'Report reminder length must be less than the reporting time.';
  }

  return errors;
};

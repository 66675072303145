import React from 'react';

import AvatarList from 'components/avatar-list';

const StandupStats = ({ stats }) => {
  return (
    <>
      <div className="bg-white overflow-hidden shadow rounded-lg px-8 py-4">
        <div className="text-sm font-medium text-gray-500 truncate">
          Not yet reported
        </div>
        <div className="mt-1 text-3xl font-semibold text-gray-900">
          {stats.incomplete.length ? (
            <AvatarList users={stats.incomplete} />
          ) : (
            <div className="mt-1 text-3xl font-semibold text-gray-900">
              None
            </div>
          )}
        </div>
      </div>
      <div className="bg-white overflow-hidden shadow rounded-lg px-8 py-4">
        <div className="text-sm font-medium text-gray-500 truncate">
          Reported absent
        </div>
        {stats.absent.length ? (
          stats.absent.map((user) => (
            <span key={user.avatar} className="mr-2" data-tip={user.name}>
              <img
                className="inline-block h-9 w-9 rounded-full ring-2 ring-transparent"
                src={user.avatar}
                alt={user.name}
              />
            </span>
          ))
        ) : (
          <div className="mt-1 text-3xl font-semibold text-gray-900">None</div>
        )}
      </div>
    </>
  );
};

export default StandupStats;

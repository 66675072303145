import { Link } from 'react-router-dom';
import React from 'react';

import { ChevronRightIcon } from 'components/icon';
import { getChannelName } from 'utils/get-channel-name';
import { getStandupDays } from 'utils/get-standup-days';
import { buildUserMap } from 'utils/build-user-map';

const StandupListItem = ({
  channels = [],
  standup,
  showCreated = false,
  users = [],
}) => {
  const userMap = buildUserMap(users);

  return (
    <Link
      to={`/standups/${standup.standupId}`}
      className="block hover:bg-gray-50"
    >
      <div className="flex items-center px-4 py-4 sm:px-6">
        <div className="min-w-0 flex-1 flex items-center">
          <div
            className={`min-w-0 flex-1 px-4 md:grid ${
              showCreated
                ? 'md:grid-cols-5 md:gap-2'
                : 'md:grid-cols-4 md:gap-3'
            }`}
          >
            <div className="flex items-center">
              <div className="text-md font-medium text-gray-900 align-middle items-center truncate">
                <span
                  className={`w-4 h-4 rounded-full bg-gray-200 inline-block align-text-bottom mr-2 ${
                    standup.enabled ? 'bg-green-400' : 'bg-red-400'
                  }`}
                />{' '}
                {standup.name}
              </div>
            </div>
            {showCreated && (
              <div className="hidden md:block">
                <div className="text-sm font-medium text-gray-900 truncate">
                  {userMap[standup.slackOwnerId]?.name}
                </div>
                <div className="text-xs text-gray-500">Created By</div>
              </div>
            )}
            <div className="hidden md:block">
              <div className="text-sm font-medium text-gray-900 truncate">
                #{getChannelName(channels, standup.channels[0])}
              </div>
              <div className="text-xs text-gray-500">Broadcast Channel</div>
            </div>
            <div className="hidden md:block">
              <div className="text-sm font-medium text-gray-900">
                {standup.participants.length}
              </div>
              <div className="text-xs text-gray-500">Participant(s)</div>
            </div>
            <div className="hidden md:block">
              <div className="text-sm font-medium text-gray-900">
                {getStandupDays(standup.reportDays)}
              </div>
              <div className="text-xs text-gray-500">Report Days</div>
            </div>
          </div>
        </div>
        <div>
          <ChevronRightIcon className="h-5 w-5 text-gray-400" />
        </div>
      </div>
    </Link>
  );
};

export default StandupListItem;

import { types } from './types';

export const setStandups = ({
  byStandupId,
  allStandupIds,
  questionsByStandupId,
}) => {
  return {
    type: types.SET_STANDUPS,
    payload: {
      byStandupId,
      allStandupIds,
      questionsByStandupId,
    },
  };
};

import { reducerFactory } from 'reducers/reducer-factory';
import { types } from 'actions/slack';

import { setData } from './set-data';
import { setLoading } from './set-loading';

export const defaults = {
  isLoading: true,
  channels: [],
  users: [],
};

const actionMap = {
  [types.SET_DATA]: setData,
  [types.SET_LOADING]: setLoading,
};

export default reducerFactory(actionMap, defaults);

export const setStandup = ({ state, action }) => {
  const { standup, questions } = action.payload;

  const questionObj = {};
  for (let i = 0; i < questions.length; i++) {
    const question = questions[i];
    questionObj[question.questionId] = question;
  }

  return {
    ...state,
    isLoading: false,
    allStandupIds: [standup.standupId, ...state.allStandupIds],
    byStandupId: {
      ...state.byStandupId,
      [standup.standupId]: standup,
    },
    questionsByStandupId: {
      ...state.questionsByStandupId,
      [standup.standupId]: questionObj,
    },
  };
};

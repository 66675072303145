import React from 'react';

const ParticipantStats = ({ stats }) => {
  return (
    <>
      <div className="bg-white overflow-hidden shadow rounded-lg px-8 py-4">
        <div className="text-sm font-medium text-gray-500 truncate">
          Reporting Percent
        </div>
        <div className="mt-1 text-3xl font-semibold text-gray-900">
          {stats ? stats.reporting : 0}%
        </div>
      </div>
      <div className="bg-white overflow-hidden shadow rounded-lg px-8 py-4">
        <div className="text-sm font-medium text-gray-500 truncate">
          Absences
        </div>
        <div className="mt-1 text-3xl font-semibold text-gray-900">
          {stats ? stats.absences : 0}
        </div>
      </div>
    </>
  );
};

export default ParticipantStats;

import { types } from './types';

export const updateStandupEnabled = ({ standupId, enabled }) => {
  return {
    type: types.UPDATE_STANDUP_ENABLED,
    payload: {
      standupId,
      enabled,
    },
  };
};

import { useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import React, { useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';

import { getStandupArray } from 'utils/get-standup-array';
import { SearchIcon } from 'components/icon';
import { usePageTitle } from 'hooks/use-page-title';
import { useOnboarding } from 'hooks/use-onboarding';
import Button from 'components/button';
import Container from 'components/container';
import Loading from 'components/loading';
import NoStandups from 'components/no-standups';
import StandupListItem from 'components/standup-list-item';
import Subheader from 'components/subheader';

const HomeRoute = () => {
  usePageTitle('My Standups');
  useOnboarding();
  const slackUserId = useSelector((state) => state.auth.user.slackUserId);
  const slackData = useSelector((state) => state.slack);
  const standups = useSelector((state) => state.standups);
  const isActive = useSelector((state) => state.auth.user.isActive);
  const [filteredStandups, setFilteredStandups] = useState([]);
  const filterRef = useRef();

  useEffect(() => {
    setFilteredStandups(
      getStandupArray(standups.allStandupIds, standups.byStandupId, slackUserId)
    );
  }, [standups.allStandupIds, standups.byStandupId, slackUserId]);

  if (slackData.isLoading || standups.isLoading) {
    return (
      <div className="text-center min-h-screen bg-gray-50 flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8">
        <Loading className="inline text-indigo-500" width={75} height={75} />
      </div>
    );
  }

  const allStandups = getStandupArray(
    standups.allStandupIds,
    standups.byStandupId,
    slackUserId
  );

  const fuse = new Fuse(allStandups, {
    keys: ['name'],
  });

  const handleFilteredResults = (e) => {
    if (e.target.value.length < 3) {
      setFilteredStandups(allStandups);
      return;
    }

    const results = fuse.search(e.target.value);
    setFilteredStandups(results.map((result) => result.item));
  };

  const handleClearFilter = () => {
    setFilteredStandups(allStandups);
    filterRef.current.value = '';
  };

  const debouncedFilter = throttle(handleFilteredResults, 300);

  return (
    <div>
      <Subheader
        title="My Standups"
        sideActions={() => (
          <Button type="primary" to={isActive ? '/create' : '/billing'}>
            Create new standup
          </Button>
        )}
      />
      <Container>
        <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h3 className="text-sm leading-6 font-medium text-gray-900">
            {allStandups.length} Standup{allStandups.length === 1 ? '' : 's'}
          </h3>
          <div className="mt-3 sm:mt-0 sm:ml-4">
            <label htmlFor="search_standups" className="sr-only">
              Search
            </label>
            <div className="flex rounded-md shadow-sm">
              <div className="relative flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  name="search_standups"
                  id="search_standups"
                  onChange={debouncedFilter}
                  className="focus:ring-indigo-500 focus:border-indigo-500 w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                  placeholder="Filter standups"
                  ref={filterRef}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white shadow overflow-hidden sm:rounded-md">
          {allStandups.length ? (
            <ul className="divide-y divide-gray-200">
              {filteredStandups.length ? (
                filteredStandups.map((standup) => (
                  <li key={standup.standupId}>
                    <StandupListItem
                      standup={standup}
                      channels={slackData.channels}
                    />
                  </li>
                ))
              ) : (
                <div className="p-4 text-center text-gray-800">
                  Your filter returned no results.{' '}
                  <button
                    className="text-indigo-600"
                    onClick={handleClearFilter}
                  >
                    Clear filter
                  </button>
                </div>
              )}
            </ul>
          ) : (
            <div className="p-4">
              <NoStandups />
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default HomeRoute;

import { Transition } from '@headlessui/react';
import React, { useRef } from 'react';

import Button from 'components/button';
import { useClickOutside } from 'hooks/use-click-outside';

const QuestionDelete = ({ isOpen, onConfirm, onClose }) => {
  const ref = useRef();

  useClickOutside(ref, onClose);

  return (
    <Transition
      show={isOpen}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0"
      enterTo="translate-y-0 opacity-100"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="absolute bottom-full right-0 mb-2 -mr-1" ref={ref}>
        <div
          className="bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5"
          style={{ width: 270 }}
        >
          <div className="p-4">Do you want to delete this question?</div>
          <div className="px-4 pb-4 text-right">
            <Button type="danger" className="mr-2" onClick={onConfirm}>
              Yes
            </Button>
            <Button onClick={onClose}>No</Button>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default QuestionDelete;

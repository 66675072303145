import React from 'react';

import Logo from 'components/logo';

const WelcomeHeader = ({ onLogout }) => {
  return (
    <div className="absolute top-0 left-0 right-0">
      <div className="flex justify-center items-center p-8">
        <div className="flex-grow">
          <Logo className="text-indigo-600" />
        </div>
        <div>
          <button
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-gray-700 bg-indigo-50 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={onLogout}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default WelcomeHeader;

import React from 'react';

import { buildUserMap } from 'utils/build-user-map';
import { buildQuestionMap } from 'utils/build-question-map';
import Report from 'components/report';
import QuestionAnswers from 'components/question-answers';

const ReportResults = ({
  reportData,
  standup,
  participantFilter,
  questionFilter,
  questions,
  users,
  view,
}) => {
  if (!reportData) return null;

  const reports = reportData.reports;
  const userMap = buildUserMap(users);

  if (view === 2) {
    const questionMap = buildQuestionMap(reports, users);
    const allQuestions = Object.keys(questionMap);

    return (
      <div className="px-4 pt-5 sm:px-6 sm:pt-6">
        {allQuestions.length ? (
          allQuestions.map((question) => (
            <QuestionAnswers
              key={question}
              question={questions[question]}
              answers={questionMap[question]}
              questionFilter={questionFilter}
              participantFilter={participantFilter}
              userMap={userMap}
            />
          ))
        ) : (
          <div className="pb-6 text-lg text-gray-600 italic">
            No questions have been answered yet...
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="px-4 pt-5 sm:px-6 sm:pt-6">
      {standup.participants.map((participant) => {
        if (!participantFilter[participant]) return null;

        const report = reports.find((r) => r.slackUserId === participant) || {};

        return (
          <Report
            key={participant}
            user={userMap[participant]}
            report={report}
            questionFilter={questionFilter}
            questions={questions}
            standupId={standup.standupId}
            participantId={participant}
          />
        );
      })}
    </div>
  );
};

export default ReportResults;

import { combineReducers } from 'redux';

import auth from './auth';
import reports from './reports';
import slack from './slack';
import standups from './standups';

const rootReducer = combineReducers({
  auth,
  reports,
  slack,
  standups,
});

export default rootReducer;

import store from 'store';

export const getStandupArray = (
  standupList,
  byStandupId = false,
  slackUserId = false
) => {
  const standupObj = byStandupId
    ? byStandupId
    : store.getState().standups.byStandupId;

  const standups = [];
  for (let i = 0; i < standupList.length; i++) {
    const currentStandup = standupObj[standupList[i]];
    if (currentStandup) {
      if (slackUserId) {
        if (slackUserId === currentStandup.slackOwnerId) {
          standups.push(currentStandup);
        }
      } else {
        standups.push(currentStandup);
      }
    }
  }

  return standups;
};

import get from 'lodash/get';

export const formatExportReports = (
  reports,
  users,
  questions,
  allQuestions
) => {
  const results = [];

  users.forEach((user) => {
    const report = reports.find((r) => r.slackUserId === user.id) || false;

    const reportResults = {};
    questions.forEach((question) => {
      reportResults[allQuestions[question].content] = report
        ? get(report, `results.${question}`, '-')
        : '-';
    });

    if (report) {
      results.push({
        name: user.name,
        status: report.responseStatus,
        date: report.respondedAt || '-',
        ...reportResults,
      });
    } else {
      results.push({
        name: user.name,
        status: 'incomplete',
        date: '-',
        ...reportResults,
      });
    }
  });

  return results;
};

export const enableAllQuestions = (questions) => {
  const allQuestions = Object.keys(questions);
  const filters = {};

  for (let i = 0; i < allQuestions.length; i++) {
    const q = allQuestions[i];
    filters[q] = true;
  }

  return filters;
};

import { setLoading, setRestrictions } from 'actions/auth';
import { putRequest } from 'utils/fetch';

import { createThunk } from '../create-thunk';

export const updateRestrictions = createThunk({
  handler: async ({ args }) => {
    await putRequest('/team/restrictions', args.data, true);

    return {
      userRestrictionEnabled: args.data.userRestrictionEnabled,
      userAllowlist: args.data.userAllowlist,
      additionalAdmins: args.data.additionalAdmins,
    };
  },
  onStart: [() => setLoading(true)],
  onSuccess: [setRestrictions],
  onFailure: [() => setLoading(false)],
});

import clsx from 'clsx';
import React from 'react';

import { AlertIcon } from 'components/icon';

const classes = {
  base:
    'appearance-none block w-full  py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm disabled:bg-gray-50',
  normal:
    'px-3 border-gray-300 placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500',
  error:
    'pl-3 pr-10 border-red-300 placeholder-red-300 text-red-900 focus:ring-red-500 focus:border-red-500',
};

const Input = ({
  autocomplete = false,
  className = '',
  error = false,
  hint = false,
  name,
  type = 'text',
  placeholder = '',
  onChange,
  onBlur,
  value,
  disabled,
  width = false,
}) => {
  const inputClassName = clsx(
    classes.base,
    !error && classes.normal,
    error && classes.error
  );

  return (
    <div className={className}>
      <div
        className="mt-1 relative rounded-md shadow-sm"
        style={{ ...(width ? { width } : {}) }}
      >
        <input
          id={name}
          name={name}
          type={type}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          value={value}
          autoComplete={autocomplete ? autocomplete : undefined}
          className={inputClassName}
          disabled={disabled}
        />
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <AlertIcon className="h-5 w-5 text-red-500" />
          </div>
        )}
      </div>
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
      {hint && !error && <p className="mt-2 text-xs text-gray-400">{hint}</p>}
    </div>
  );
};

export default Input;

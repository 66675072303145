import { types } from './types';

export const deleteStandupQuestion = ({ standupId, questionId, question }) => {
  return {
    type: types.DELETE_STANDUP_QUESTION,
    payload: {
      standupId,
      questionId,
      question,
    },
  };
};

import React from 'react';

import { usePageTitle } from 'hooks/use-page-title';

import { ReactComponent as Graphic } from './graphic.svg';

const OAuthErrorRoute = () => {
  usePageTitle('Authentication Error', false);
  setInterval(() => {
    window.location.replace(process.env.REACT_APP_MARKETING_URL);
  }, 2000);
  return (
    <div className="flex flex-col min-h-screen items-center justify-center p-8">
      <div className="max-w-lg text-center">
        <Graphic className="w-full mb-8" />
        <h1 className="text-3xl font-bold text-gray-900 mb-4">
          Authentication Error
        </h1>
        <p className="text-gray-600 mb-4">
          An error has occured while trying to log you in.
        </p>
        <a
          href={process.env.REACT_APP_MARKETING_URL}
          className="font-medium text-indigo-600 hover:text-indigo-500"
        >
          Go To Homepage &rarr;
        </a>
      </div>
    </div>
  );
};

export default OAuthErrorRoute;

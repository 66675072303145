import { putRequest } from 'utils/fetch';

import { createThunk } from '../create-thunk';

export const updateStandupQuestion = createThunk({
  handler: async ({ args }) => {
    await putRequest(
      `/standups/${args.standupId}/question/${args.questionId}`,
      args.data,
      true
    );

    return {};
  },
  onStart: [],
  onSuccess: [],
  onFailure: [],
});
